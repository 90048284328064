const Table = require('../../Misc/Table');
export const nutritionAndFeeding = function (app, report, evaluation, group, assessor) {
    const isImperia = () => evaluation.getMeasurements() === 'imperial'
    console.log(report.pptx.slides)
    let slide = report.pptx.addSlide('Slide master');
    let slide2;
    let slide3;

    let titleY = 2.2;
    let tableY = 2.7;

    slide.addText(
        app.$t('Nutrition and Feeding Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
    );

    const standardCategories = [{
      text: app.$t('Question'),
      options: report.styleOptions.tableHeader
    }, {
      text: app.$t('Answer'),
      options: report.styleOptions.tableHeader
    }, {
      text: app.$t('Comment'),
      options: report.styleOptions.tableHeader
    }];
    const nutritionSupplyCategories = [{
      text: app.$t('Question'),
      options: report.styleOptions.tableHeader
    }, {
      text: app.$t('Answer'),
      options: report.styleOptions.tableHeader
    }, {
      text: app.$t('Target'),
      options: report.styleOptions.tableHeader
    }, {
      text: app.$t('Type'),
      options: report.styleOptions.tableHeader

    }, {
      text: app.$t('Comment'),
      options: report.styleOptions.tableHeader
    }];
    const zinproPerformanceMineralsCategories = [{
      text: app.$t('Question'),
      options: report.styleOptions.tableHeader
    }, {
      text: app.$t('Answer'),
      options: report.styleOptions.tableHeader
    }, {
      text: app.$t('Recommended Amount *'),
      options: report.styleOptions.tableHeader
    }, {
      text: app.$t('Comment'),
      options: report.styleOptions.tableHeader
    }];
    let feedAccessObj = [];
    let riskOfAcidosisObj = [];
    let nutritionSupplyObj = [];
    let zinproPerformanceMineralsObj = [];

    let resultMessage;

    //POPULATE Feed Access OBJECT HERE
    if (assessor.getFeedSpace() !== null) {
        resultMessage = assessor.getResultMessage('feed_space');
        feedAccessObj.push([{
                text: app.$t('Feed Space (') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'cm')) + '/' + app.$t('cow') + ')'
            },
            {
                text: assessor.getFeedSpace()
            },
            {
                text: resultMessage.feedback.response,
                options: {
                    color: resultMessage.feedback.mood === 'text-success' ? '249824' : 'a40000',
                    align: 'left',
                    fill: 'efefef',
                }
            },
        ]);
        tableY += .55;
        titleY += .55;
        console.log('HIT 2')
    }

    if (assessor.getPercentageOfGroupLyingDownChewingCud() !== null && assessor.getPercentageOfGroupLyingDownChewingCud() !== '') {
        resultMessage = assessor.getResultMessage('percentage_of_group_lying_down_chewing_cud');
        feedAccessObj.push([{
                text: app.$t('Percentage of Group Lying Down Chewing Cud')
            },
            {
                text: app.$numberFormat(assessor.getPercentageOfGroupLyingDownChewingCud())
            },
            {
                text: resultMessage.feedback.response,
                options: {
                    color: resultMessage.feedback.mood === 'text-success' ? '249824' : 'a40000',
                    align: 'left',
                    fill: 'efefef',
                }
            },
        ]);
        tableY += .55;
        titleY += .55;
        console.log('HIT 3')
    }

    if (assessor.getTimeOfLyingDownChewingCudAssessment() !== null && assessor.getTimeOfLyingDownChewingCudAssessment() !== '') {
        feedAccessObj.push([{
                text: app.$t('What was the time relative to milking or feeding that cud chewing was assessed?')
            },
            {
                text: assessor.getTimeOfLyingDownChewingCudAssessment()
            },
            {
                text: '',
                options: {
                    color: '000000',
                    align: 'left',
                    fill: 'efefef'
                }
            },
        ]);
        tableY += .55;
        titleY += .55;
        console.log('HIT 4')
    }

    if (assessor.getAreThereAnyEmptyFeedBunksAfterMilking() !== null) {
        feedAccessObj.push([{
                text: app.$t('Are there any empty feed bunks after milking?'),
            },
            {
                text: assessor.getAreThereAnyEmptyFeedBunksAfterMilking() === 1 ? app.$t('Yes') : assessor.getAreThereAnyEmptyFeedBunksAfterMilking() === 0 ? app.$t('No') : app.$t('N/A')
            },
            {
                text: assessor.getAreThereAnyEmptyFeedBunksAfterMilking() === 1 ? app.$t('Cows returning from milking must have access to fresh or pushed up feed.') : assessor.getAreThereAnyEmptyFeedBunksAfterMilking() === 0 ? app.$t('Good!') : '',
                options: {
                    color: assessor.getAreThereAnyEmptyFeedBunksAfterMilking() === 1 ? 'a40000' : '249824',
                    align: 'left',
                    fill: 'efefef'
                }
            },
        ]);
        assessor.getAreThereAnyEmptyFeedBunksAfterMilking() === 1 ? tableY += .65 : tableY += .5;
        assessor.getAreThereAnyEmptyFeedBunksAfterMilking() === 1 ? titleY += .65 : titleY += .5;
        console.log('HIT 5 1')
    }

    if (assessor.getAreGrazingCowsHeldOffPastureForProlongedPeriodsOtherThanMilkingTime() !== null) {
        feedAccessObj.push([{
                text: app.$t('Are grazing cows held off pasture for prolonged periods (> 1 hour) other than milking time?')
            },
            {
                text: assessor.getAreGrazingCowsHeldOffPastureForProlongedPeriodsOtherThanMilkingTime() === 1 ? app.$t('Yes') : assessor.getAreGrazingCowsHeldOffPastureForProlongedPeriodsOtherThanMilkingTime() === 0 ? app.$t('No') : app.$t('N/A')
            },
            {
                text: assessor.getAreGrazingCowsHeldOffPastureForProlongedPeriodsOtherThanMilkingTime() === 1 ? app.$t('Prolonged time off pasture will lead to over-eating and low rumen pH.') : assessor.getAreGrazingCowsHeldOffPastureForProlongedPeriodsOtherThanMilkingTime() === 0 ? app.$t('Good!') : '',
                options: {
                    color: assessor.getAreGrazingCowsHeldOffPastureForProlongedPeriodsOtherThanMilkingTime() === 1 ? 'a40000' : '249824',
                    align: 'left',
                    fill: 'efefef'
                }
            },
        ]);
        tableY += .65;
        titleY += .65;
        console.log('HIT 5 2')
    }

    if (feedAccessObj.length > 0) {
        // add one if feed access object is populated. fixes layout issue if risk acidosis object is populated and this one is not
        titleY += 1;
        tableY += 1;
        slide.addText(
            app.$t('Feed Access'), {
                x: 0.5,
                y: 2.38,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide, standardCategories, feedAccessObj, report, group, 'Nutrition and Feeding Assessor', {
            y: 2.7,
            colW: [3.25, 1.25, 3],
            ...report.styleOptions.tableOptions
        });
    }

    //POPULATE Risk of Acidosis OBJECT HERE
    if (assessor.getDryMatter() !== null && assessor.getDryMatter() !== '') {
        resultMessage = assessor.getResultMessage('dry_matter');
        riskOfAcidosisObj.push([{
            text: app.$t('Dry Matter %')
        }, {
            text: app.$numberFormat(assessor.getDryMatter())
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-success' ? '249824' : 'a40000',
                align: 'left',
                fill: 'efefef',
            }
        }]);
        console.log('HIT 6')
    }

    if (assessor.getTmrTopScreen() !== null && assessor.getTmrTopScreen() !== '') {
        resultMessage = assessor.getResultMessage('tmr_top_screen');
        riskOfAcidosisObj.push([{
            text: app.$t('TMR Top Screen %')
        }, {
            text: app.$numberFormat(assessor.getTmrTopScreen())
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-success' ? '249824' : 'a40000',
                align: 'left',
                fill: 'efefef',
            }
        }]);
        console.log('HIT 7')
    }

    if (assessor.getTmrMiddleScreen() !== null && assessor.getTmrMiddleScreen() !== '') {
        riskOfAcidosisObj.push([{
            text: app.$t('TMR Middle Screen %')
        }, {
            text: app.$numberFormat(assessor.getTmrMiddleScreen())
        }, {
            text: app.$t('Ideal range is ') + app.$numberFormat(assessor.getMinTmrMiddleScreen()) + app.$t(' to ') + app.$numberFormat(assessor.getMaxTmrMiddleScreen()) + '%.',
            options: {
                color: '000000',
                align: 'left',
                fill: 'efefef',
            }
        }]);
        console.log('HIT 8');
    }

    if (assessor.getTmrBottomScreen() !== null && assessor.getTmrBottomScreen() !== '') {
        riskOfAcidosisObj.push([{
            text: app.$t('TMR Bottom Screen %')
        }, {
            text: app.$numberFormat(assessor.getTmrBottomScreen())
        }, {
            text: app.$t('Ideal range is ') + app.$numberFormat(assessor.getMinTmrBottomScreen()) + app.$t(' to ') + app.$numberFormat(assessor.getMaxTmrBottomScreen()) + '%.',
            options: {
                color: '000000',
                align: 'left',
                fill: 'efefef',
            }
        }]);
        console.log('HIT 9');
    }

    if (assessor.getTmrPan() !== null && assessor.getTmrPan() !== '') {
        riskOfAcidosisObj.push([{
            text: app.$t('TMR Pan %')
        }, {
            text: assessor.getTmrPan()
        }, {
            text: app.$t('Ideal range is ') + app.$numberFormat(assessor.getMinTmrPan()) + app.$t(' to ') + app.$numberFormat(assessor.getMaxTmrPan()) + '%.',
            options: {
                color: '000000',
                align: 'left',
                fill: 'efefef',
            }
        }]);
        console.log('HIT 10');
    }

    if (assessor.getAreFeedDeliveryAndPushupsTimedToMaximizeFeedAccess() !== null) {
        riskOfAcidosisObj.push([{
            text: app.$t('Are feed delivery and pushups timed to maximize feed access?'),
        }, {
            text: assessor.getAreFeedDeliveryAndPushupsTimedToMaximizeFeedAccess() === 1 ? app.$t('Yes') : assessor.getAreFeedDeliveryAndPushupsTimedToMaximizeFeedAccess() === 0 ? app.$t('No') : app.$t('N/A'),
        }, {
            text: assessor.getAreFeedDeliveryAndPushupsTimedToMaximizeFeedAccess() === 1 ? app.$t('Good!') : assessor.getAreFeedDeliveryAndPushupsTimedToMaximizeFeedAccess() === 0 ? app.$t('Feed delivery and pushups should be timed to maximize access to feed.') : '',
            options: {
                color: assessor.getAreFeedDeliveryAndPushupsTimedToMaximizeFeedAccess() === 1 ? '249824' : 'a40000',
                align: 'left',
                fill: 'efefef',
            }
        }]);
        console.log('HIT 11')
    }

    if (assessor.getAreCowsFedMoreThanOfConcentrateGrainInTheParlor() !== null) {
        riskOfAcidosisObj.push([{
            // text: app.$t('Are cows fed more than ') + assessor.getMinAreCowsFedMoreThanOfConcentrateGrainInTheParlor() + ' ' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'kg')) + app.$t(' of concentrate/grain in the parlor?'),
       text: evaluation.isImperial ? app.$t('Are cows fed more than 8 lb of concentrate/grain in the parlor?') : app.$t('Are cows fed more than 3.6 kg of concentrate/grain in the parlor?')
        }, {
            text: assessor.getAreCowsFedMoreThanOfConcentrateGrainInTheParlor() === 1 ? app.$t('Yes') : assessor.getAreCowsFedMoreThanOfConcentrateGrainInTheParlor() === 0 ? app.$t('No') : app.$t('N/A'),
        }, {
            text: assessor.getAreCowsFedMoreThanOfConcentrateGrainInTheParlor() === 1 ? app.$t('Individually feeding cows large amounts of concentrate in a single meal will result in sudden drops in rumen pH.') : assessor.getAreCowsFedMoreThanOfConcentrateGrainInTheParlor() === 0 ? app.$t('Good!') : '',
            options: {
                color: assessor.getAreCowsFedMoreThanOfConcentrateGrainInTheParlor() === 1 ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef',
            }
        }]);
        console.log('HIT 12')
    }

    if (assessor.getIsThereAHighlyFermentableCarbohydrateFeedDeliveredSeparateAtAnyTime() !== null) {
        riskOfAcidosisObj.push([{
            text: app.$t('Is there a highly fermentable carbohydrate feed delivered separate at any time?'),
        }, {
            text: assessor.getIsThereAHighlyFermentableCarbohydrateFeedDeliveredSeparateAtAnyTime() === 1 ? app.$t('Yes') : assessor.getIsThereAHighlyFermentableCarbohydrateFeedDeliveredSeparateAtAnyTime() === 0 ? app.$t('No') : app.$t('N/A'),
        }, {
            text: assessor.getIsThereAHighlyFermentableCarbohydrateFeedDeliveredSeparateAtAnyTime() === 1 ? app.$t('Individually feeding cows large amounts of fermentable carbohydrate feeds will result in sudden drops in rumen pH.') : assessor.getIsThereAHighlyFermentableCarbohydrateFeedDeliveredSeparateAtAnyTime() === 0 ? app.$t('Good!') : '',
            options: {
                color: assessor.getIsThereAHighlyFermentableCarbohydrateFeedDeliveredSeparateAtAnyTime() === 1 ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef',
            }
        }]);
        console.log('HIT 13')
    }

    if (assessor.getIfRumenocentesisHasBeenPerformedDoesTheBestIndicateAProblemWithSara() !== null) {
        riskOfAcidosisObj.push([{
            text: app.$t('If rumenocentesis has been performed, does the test indicate a problem with SARA (Sub-Acute Ruminal Acidosis)?'),
        }, {
            text: assessor.getIfRumenocentesisHasBeenPerformedDoesTheBestIndicateAProblemWithSara() === 1 ? app.$t('Yes') : assessor.getIfRumenocentesisHasBeenPerformedDoesTheBestIndicateAProblemWithSara() === 0 ? app.$t('No') : app.$t('N/A'),
        }, {
            text: assessor.getIfRumenocentesisHasBeenPerformedDoesTheBestIndicateAProblemWithSara() === 1 ? app.$t('Risk factors for SARA must be investigated and reduced.') : assessor.getIfRumenocentesisHasBeenPerformedDoesTheBestIndicateAProblemWithSara() === 0 ? app.$t('Good!') : '',
            options: {
                color: assessor.getIfRumenocentesisHasBeenPerformedDoesTheBestIndicateAProblemWithSara() === 1 ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef',
            }
        }]);
        console.log('HIT 14');
    }

    if (assessor.getIsACompleteNutrientWorkupRequired() !== null) {
        riskOfAcidosisObj.push([{
            text: app.$t('Is a complete nutrient workup required?'),
        }, {
            text: assessor.getIsACompleteNutrientWorkupRequired() === 1 ? app.$t('Yes') : assessor.getIsACompleteNutrientWorkupRequired() === 0 ? app.$t('No') : app.$t('N/A'),
        }, {
            text: assessor.getIsACompleteNutrientWorkupRequired() === 1 ? app.$t('We recommend a complete herd nutritional workup.') : assessor.getIsACompleteNutrientWorkupRequired() === 0 ? app.$t('Good!') : '',
            options: {
                color: assessor.getIsACompleteNutrientWorkupRequired() === 1 ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef',
            }
        }]);
        console.log('HIT 15');
    }

    if (assessor.getRuminationTimePerDay() !== null) {
        resultMessage = assessor.getResultMessage('rumination_time_per_day');
        riskOfAcidosisObj.push([{
            text: app.$t('Rumination Time per Day (minutes)')
        }, {
            text: app.$numberFormat(assessor.getRuminationTimePerDay())
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-success' ? '249824' : 'a40000',
                align: 'left',
                fill: 'efefef',
            }
        }]);
        console.log('HIT 16');
    }

    if (riskOfAcidosisObj.length > 0) {
        slide.addText(
            app.$t('Risk of Acidosis'), {
                x: 0.5,
                y: titleY + .2,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide, standardCategories, riskOfAcidosisObj, report, group, 'Nutrition and Feeding Assessor', {
            y: tableY,
            colW: [3.25, 1.25, 3],
            ...report.styleOptions.tableOptions
        });
    }

    //POPULATE Nutrition Supply OBJECT HERE
    if (assessor.getDryMatterIntake() !== null) {
        nutritionSupplyObj.push([{
            text: app.$t('Dry Matter Intake (') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'kg')) + app.$t('/day)'),
        }, {
            text: app.$numberFormat(assessor.getDryMatterIntake()),
        }, {
            text: ''
        }, {
            text: ''
        }, {
            text: ''
        }]);
        console.log('HIT 17');
    }

    if (assessor.getBuffer() !== null) {
        resultMessage = assessor.getResultMessage('buffer');
        nutritionSupplyObj.push([{
            text: app.$t('Buffer (') + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'g')) + app.$t('/cow/day)')
        }, {
            text: app.$numberFormat(assessor.getBuffer())
        }, {
            text: resultMessage.target
        }, {
            text: ''
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-success' ? '249824' : 'a40000',
                align: 'left',
                fill: 'efefef',
            }
        }]);
        console.log('HIT 18');
    }

    if (assessor.getForageNdf() !== null) {
        resultMessage = assessor.getResultMessage('forage_ndf');
        nutritionSupplyObj.push([{
            text: app.$t('Forage NDF %')
        }, {
            text: app.$numberFormat(assessor.getForageNdf())
        }, {
            text: resultMessage.target
        }, {
            text: ''
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 19');
    }

    if (assessor.getNdf() !== null) {
        resultMessage = assessor.getResultMessage('ndf');
        nutritionSupplyObj.push([{
            text: app.$t('NDF %')
        }, {
            text: app.$numberFormat(assessor.getNdf())
        }, {
            text: resultMessage.target
        }, {
            text: ''
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 20');
    }

    if (assessor.getNfc() !== null) {
        resultMessage = assessor.getResultMessage('nfc');
        nutritionSupplyObj.push([{
            text: app.$t('NFC %')
        }, {
            text: app.$numberFormat(assessor.getNfc())
        }, {
            text: resultMessage.target
        }, {
            text: ''
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 21');
    }

    if (assessor.getAdf() !== null) {
        resultMessage = assessor.getResultMessage('adf');
        nutritionSupplyObj.push([{
            text: app.$t('ADF %')
        }, {
            text: app.$numberFormat(assessor.getAdf())
        }, {
            text: resultMessage.target
        }, {
            text: ''
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 22');
    }

    if (assessor.getCp() !== null) {
        resultMessage = assessor.getResultMessage('cp');
        nutritionSupplyObj.push([{
            text: app.$t('CP %')
        }, {
            text: app.$numberFormat(assessor.getCp())
        }, {
            text: resultMessage.target
        }, {
            text: ''
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 23');
    }

    if (assessor.getCa() !== null) {
        resultMessage = assessor.getResultMessage('ca');
        nutritionSupplyObj.push([{
            text: app.$t('Ca %')
        }, {
            text: app.$numberFormat(assessor.getCa())
        }, {
            text: resultMessage.target
        }, {
            text: ''
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 24');
    }

    if (assessor.getP() !== null) {
        resultMessage = assessor.getResultMessage('p');
        nutritionSupplyObj.push([{
            text: app.$t('P %')
        }, {
            text: app.$numberFormat(assessor.getP())
        }, {
            text: resultMessage.target
        }, {
            text: ''
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 25');
    }

    if (assessor.getK() !== null) {
        resultMessage = assessor.getResultMessage('k');
        console.log('k resultMessage', resultMessage);
        nutritionSupplyObj.push([{
            text: app.$t('K %')
        }, {
            text: app.$numberFormat(assessor.getK())
        }, {
            text: resultMessage.target
        }, {
            text: ''
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 26');
    }

    if (assessor.getMg() !== null) {
        resultMessage = assessor.getResultMessage('mg');
        nutritionSupplyObj.push([{
            text: app.$t('Mg %')
        }, {
            text: app.$numberFormat(assessor.getMg())
        }, {
            text: resultMessage.target
        }, {
            text: ''
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 27');
    }

    if (assessor.getS() !== null) {
        resultMessage = assessor.getResultMessage('s');
        nutritionSupplyObj.push([{
            text: app.$t('S %')
        }, {
            text: app.$numberFormat(assessor.getS())
        }, {
            text: resultMessage.target
        }, {
            text: ''
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 28');
    }

    if (assessor.getZnPpm() !== null) {
        resultMessage = assessor.getResultMessage('zn_ppm');
        nutritionSupplyObj.push([{
            text: app.$t('Zn ppm')
        }, {
            text: app.$numberFormat(assessor.getZnPpm())
        }, {
            text: resultMessage.target
        }, {
            text: resultMessage.type
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 29');
    }

    if (assessor.getCuPpm() !== null) {
        resultMessage = assessor.getResultMessage('cu_ppm');
        nutritionSupplyObj.push([{
            text: app.$t('Cu ppm')
        }, {
            text: app.$numberFormat(assessor.getCuPpm())
        },  {
            text: resultMessage.target
        }, {
            text: resultMessage.type
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 30');
    }

    if (assessor.getMnPpm() !== null) {
        resultMessage = assessor.getResultMessage('mn_ppm');
        nutritionSupplyObj.push([{
            text: app.$t('Mn ppm')
        }, {
            text: app.$numberFormat(assessor.getMnPpm())
        },  {
            text: resultMessage.target
        }, {
            text: resultMessage.type
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 31');
    }

    if (assessor.getCoPpm() !== null) {
        resultMessage = assessor.getResultMessage('co_ppm');
        nutritionSupplyObj.push([{
            text: app.$t('Co ppm')
        }, {
            text: app.$numberFormat(assessor.getCoPpm())
        },  {
            text: resultMessage.target
        }, {
            text: resultMessage.type
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 32');
    }

    if (assessor.getIPpm() !== null) {
        resultMessage = assessor.getResultMessage('i_ppm');
        nutritionSupplyObj.push([{
            text: app.$t('I ppm')
        }, {
            text: app.$numberFormat(assessor.getIPpm())
        },  {
            text: resultMessage.target
        }, {
            text: resultMessage.type
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 33');
    }

    if (assessor.getSePpm() !== null) {
        resultMessage = assessor.getResultMessage('se_ppm');
        nutritionSupplyObj.push([{
            text: app.$t('Se ppm')
        }, {
            text: app.$numberFormat(assessor.getSePpm())
        },  {
            text: resultMessage.target
        }, {
            text: resultMessage.type
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 34');
    }

    if (assessor.getFePpm() !== null) {
        resultMessage = assessor.getResultMessage('fe_ppm');
        nutritionSupplyObj.push([{
            text: app.$t('Fe ppm')
        }, {
            text: app.$numberFormat(assessor.getFePpm())
        },  {
            text: resultMessage.target
        }, {
            text: resultMessage.type
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 35');
    }

    if (assessor.getMoPpm() !== null) {
        resultMessage = assessor.getResultMessage('mo_ppm');
        nutritionSupplyObj.push([{
            text: app.$t('Mo ppm')
        }, {
            text: app.$numberFormat(assessor.getMoPpm())
        },  {
            text: resultMessage.target
        }, {
            text: resultMessage.type
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 36');
    }

    if (assessor.getBiotin() !== null) {
        resultMessage = assessor.getResultMessage('biotin');
        nutritionSupplyObj.push([{
            text: app.$t('Biotin') + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'mg')) + app.$t('/day)')
        }, {
            text: app.$numberFormat(assessor.getBiotin())
        }, {
            text: resultMessage.target
        }, {
            text: resultMessage.type
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
        console.log('HIT 37');
    }

    if (nutritionSupplyObj.length > 0) {

        slide2 = report.pptx.addSlide('Slide master');

        slide2.addText(
            app.$t('Nutrition and Feeding Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );

        slide2.addText(
            app.$t('Nutrition Supply'), {
                x: 0.5,
                y: 2.38,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide2, nutritionSupplyCategories, nutritionSupplyObj, report, group, 'Nutrition and Feeding Assessor', {
            y: 2.7,
            colW: [2, .75, .75, 1.25, 2.75],
            ...report.styleOptions.tableOptions
        });
    }

    //populate zinpro performance materials object here
    if (assessor.get4PlexC() !== null) {
        resultMessage = assessor.getResultMessage('four_plex_c');
        zinproPerformanceMineralsObj.push([{
            text: app.$t('Zinpro 4-Plex C') + ' (' + app.$t('g') + ')'
        }, {
            text: app.$numberFormat(assessor.get4PlexC())
        }, {
            text: resultMessage.target
        },{
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    // not original 4 plex c
    if (assessor.getAvaila4() !== null) {
        resultMessage = assessor.getResultMessage('availa_4');
        zinproPerformanceMineralsObj.push([{
            text: app.$t('Zinpro Availa 4') + ' (' + app.$t('g') + ')'
        }, {
            text: app.$numberFormat(assessor.getAvaila4())
        },{
            text: resultMessage.target
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getAvailaCu100() !== null) {
        resultMessage = assessor.getResultMessage('availa_cu_100');
        zinproPerformanceMineralsObj.push([{
            text: app.$t('Zinpro Propath Cu') + ' (' + app.$t('g') + ')'
        }, {
            text: app.$numberFormat(assessor.getAvailaCu100())
        }, {
            text: resultMessage.target
        },{
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getAvailaMn80() !== null) {
        resultMessage = assessor.getResultMessage('availa_mn_80');
        zinproPerformanceMineralsObj.push([{
            text: app.$t('Zinpro Availa MN 80') + ' (' + app.$t('g') + ')'
        }, {
            text: app.$numberFormat(assessor.getAvailaMn80())
        }, {
            text: resultMessage.target
        },{
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getAvailaZn120() !== null) {
        resultMessage = assessor.getResultMessage('availa_zn_120');
        zinproPerformanceMineralsObj.push([{
            text: app.$t('Zinpro Availa Zn 120') + ' (' + app.$t('g') + ')'
        }, {
            text: app.$numberFormat(assessor.getAvailaZn40())
        }, {
            text: resultMessage.target
        },{
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getAvailaZn40() !== null) {
        resultMessage = assessor.getResultMessage('availa_zn_40');
        zinproPerformanceMineralsObj.push([{
            text: app.$t('Zinpro Availa Zn 40') + ' (' + app.$t('g') + ')'
        }, {
            text: app.$numberFormat(assessor.getAvailaZn40())
        }, {
            text: resultMessage.target
        },{
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getAvailaPlus() !== null) {
        resultMessage = assessor.getResultMessage('availa_plus');
        zinproPerformanceMineralsObj.push([{
            text: app.$t('Zinpro Availa Plus') + ' (' + app.$t('g') + ')'
        }, {
            text: app.$numberFormat(assessor.getAvailaPlus(2))
        }, {
            text: resultMessage.target
        },{
            text: resultMessage.feedback.response,
            options: {
                color: '000000', // resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getAvailaDairy() !== null) {
        resultMessage = assessor.getResultMessage('availa_dairy');
        zinproPerformanceMineralsObj.push([{
            text: app.$t('Availa-Dairy') + ' (' + app.$t('g') + ')'
        }, {
            text: app.$numberFormat(assessor.getAvailaDairy())
        }, {
            text: resultMessage.target
        },{
            text: resultMessage.feedback.response,
            options: {
                color: '000000', // color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }


    if (assessor.getCopro25() !== null) {
        resultMessage = assessor.getResultMessage('copro_25');
        zinproPerformanceMineralsObj.push([{
            text: app.$t('Copro 25') + ' (' + app.$t('g') + ')'
        }, {
            text: app.$numberFormat(assessor.getCopro25(2))
        }, {
            text: resultMessage.target
        },{
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getCuplex100() !== null) {
        resultMessage = assessor.getResultMessage('cuplex_100');
        zinproPerformanceMineralsObj.push([{
            text: app.$t('CuPlex 100') + ' (' + app.$t('g') + ')'
        }, {
            text: app.$numberFormat(assessor.getCuplex100())
        }, {
            text: resultMessage.target
        },{
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getCuplex50() !== null) {
        resultMessage = assessor.getResultMessage('cuplex_50');
        zinproPerformanceMineralsObj.push([{
            text: app.$t('CuPlex 50') + ' (' + app.$t('g') + ')'
        }, {
            text: app.$numberFormat(assessor.getCuplex50(2))
        }, {
            text: resultMessage.target
        },{
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getManpro160() !== null) {
        resultMessage = assessor.getResultMessage('manpro_160');
        zinproPerformanceMineralsObj.push([{
            text: app.$t('Manpro 160') + ' (' + app.$t('g') + ')'
        }, {
            text: app.$numberFormat(assessor.getManpro160())
        }, {
            text: resultMessage.target
        },{
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getZinpro180() !== null) {
        resultMessage = assessor.getResultMessage('zinpro_180');
        zinproPerformanceMineralsObj.push([{
            text: app.$t('Zinpro 180')+ ' (' + app.$t('g') + ')'
        }, {
            text: app.$numberFormat(assessor.getZinpro180(2))
        }, {
            text: resultMessage.target
        },{
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getZinpro120() !== null) {
        resultMessage = assessor.getResultMessage('zinpro_120');
        zinproPerformanceMineralsObj.push([{
            text: app.$t('Zinpro 120') + ' (' + app.$t('g') + ')'
        }, {
            text: app.$numberFormat(assessor.getZinpro120(2))
        }, {
            text: resultMessage.target
        },{
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getZinpro40() !== null) {
        resultMessage = assessor.getResultMessage('zinpro_40');
        zinproPerformanceMineralsObj.push([{
            text: app.$t('Zinpro 40') + ' (' + app.$t('g') + ')'
        }, {
            text: app.$numberFormat(assessor.getZinpro40(2))
        }, {
            text: resultMessage.target
        },{
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (zinproPerformanceMineralsObj.length > 0) {

        slide3 = report.pptx.addSlide('Slide master');

        slide3.addText(
            app.$t('Nutrition and Feeding Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
        );

        slide3.addText(
            app.$t('Zinpro Performance Minerals'), {
                x: 0.5,
                y: 2.38,
                ...report.styleOptions.subheaderBlue
            }
        );

        slide3.addText(
            app.$t('*Recommended amounts are based upon the assumption that a product is the only product from Zinpro Corporation supplying a particular mineral. If more than one product from Zinpro Corporation supplies a particular mineral, contact your Zinpro representative for the recommended feeding rates.'), {
                x: 0.5,
                y: 3,
                w: 7.5,
                color: '000000',
                fontSize: 12,
                fontFace: 'Arial',
            }
        );

        Table.create(slide3, zinproPerformanceMineralsCategories, zinproPerformanceMineralsObj, report, group, 'Nutrition and Feeding Assessor', {
            y: 3.7,
            colW: [2, 1.25, 1.25, 3],
            ...report.styleOptions.tableOptions
        });
    }

    return true;

}
