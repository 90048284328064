const convert = require('convert-units');
import Assessor from './Assessor';

class NutritionAndFeeding extends Assessor {

    constructor(app, evaluation, group, assessor) {
        super(app, evaluation, group, assessor);

        this.app = app;
        this.evaluation = evaluation;
        this.group = group;
        this.assessor = assessor;

        //ideals, mins, and maxes
        this.minPercentageOfGroupLyingDownChewingCud = 40;
        this.minDryMatter = 45;
        this.maxDryMatter = 55;
        this.minTmrTopScreen = 2;
        this.maxTmrTopScreen = 8;
        this.minAreCowsFedMoreThanOfConcentrateGrainInTheParlor = 3.6;
        this.minTmrMiddleScreen = 30;
        this.maxTmrMiddleScreen = 50;
        this.minTmrBottomScreen = 10;
        this.maxTmrBottomScreen = 20;
        this.minTmrPan = 30;
        this.maxTmrPan = 40;
        this.minRuminationTimePerDay = 450;
        this.minNdf = 25;
        this.idealNfc = 44;
        this.idealNfcMin = 36;
        this.idealNfcMax = 44;
        this.idealAdf = 17;
        this.idealAdfMin = 17;
        this.idealAdfMax = 21;
        this.minBuffer = 6; // gets converted
        this.maxBuffer = 9; // gets converted
        this.minForageNdf = 15;
        this.maxForageNdf = 19;
        this.minForageCP = 16;
        this.maxForageCP = 18.5;
        this.minForageCA = .7;
        this.maxForageCA = 1.1;
        this.minForageP = .35;
        this.maxForageP = .42;
        this.minForageK = 1.2;
        this.maxForageK = 2;
        this.minForageMg = .35;
        this.maxForageMg = .4;
        this.minForageS = .2;
        this.maxForageS = .3;
        this.minForageZnPpm = 75;
        this.maxForageZnPpm = 85;
        this.minCuPpm = 10;
        this.minCuPpmSupplemental = 8;
        this.minCuPpmTotal = 14;
        this.maxCuPpm = 15;
        this.maxCuPpmSupplemental = 10;
        this.maxCuPpmTotal = 20;
        this.minZnPpm = 75;
        this.minZnPpmSupplemental = 75;
        this.minZnPpmTotal = 95;
        this.maxZnPpm = 85;
        this.maxZnPpmSupplemental = 85;
        this.maxZnPpmTotal = 110;
        this.minCp = 16;
        this.maxCp = 18.5;
        this.minP = .35;
        this.maxP = .45;
        this.minK = 1.0;
        this.maxK = 2.0;
        this.minMg = .32;
        this.maxMg = .5;
        this.minS = .2;
        this.maxS = .3;
        this.minCa = .7;
        this.maxCa = 1.2;
        this.minMnPpm = 55;
        this.minMnPpmSupplemental = 55;
        this.minMnPpmTotal = 60;
        this.maxMnPpm = 75;
        this.maxMnPpmSupplemental = 75;
        this.maxMnPpmTotal = 105;
        this.minCoPpm = .8;
        this.maxCoPpm = 1.2;
        this.minIPpm = .8;
        this.maxIPpm = 1.2;
        this.minSePpm = .2;
        this.maxSePpm = .3;
        this.minFePpm = 30;
        this.maxFePpm = 250;
        this.minBiotin = 20; // gets converted
        this.min4PlexC = 7; // gets converted
        this.minAvaila4 = 7; // gets converted
        this.minAvailaCu100 = 1.25; // gets converted
        this.minAvailaMn80 = 2.5; // gets converted
        this.minZn120 = 3; // gets converted
        this.minZn40 = 9; // gets converted
        this.minAvailaPlus = 1; // gets converted
        this.minCopro25 = 1; // gets converted
        this.minCuPlex100 = 1.25; // gets converted
        this.minCuPlex50 = 2.5; // gets converted
        this.minManpro160 = 1.25; // gets converted
        this.minZinpro120 = 3; // gets converted
        this.minZinpro180 = 2; // gets converted
        this.minZinpro40 = 9; // gets converted
    }

    // used in reporting
    getResultMessage(messageKey) {

        const messages = [
            // Feed Access
            {
                key: "feed_space",
                title: this.app.$t("Feed Space"),
                options: [{
                        response: this.app.$t("Good! Target bunk space per cow is a minimum of ") + this.getMinFeedSpace() + this.app.$t("."),
                        critera: this.getFeedSpace() >= this.getMinFeedSpace(),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t("Target bunk space per cow is a minimum of ") + this.getMinFeedSpace() + this.app.$t("."),
                        critera: this.getFeedSpace() < this.getMinFeedSpace(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "percentage_of_group_lying_down_chewing_cud",
                title: this.app.$t("Percentage of Group Lying Down Chewing Cud"),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: this.getPercentageOfGroupLyingDownChewingCud() >= this.getMinPercentageOfGroupLyingDownChewingCud(),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t("Target cud chewing rate is > ") + this.getMinPercentageOfGroupLyingDownChewingCud() + '%.',
                        critera: this.getPercentageOfGroupLyingDownChewingCud() < this.getMinPercentageOfGroupLyingDownChewingCud(),
                        mood: "text-danger"
                    }
                ]
            },
            // Risk of Acidosis
            {
                key: "dry_matter",
                title: this.app.$t("Dry Matter %"),
                options: [{
                        response: this.app.$t("Good, target is ") + this.getMinDryMatter() + '% - ' + this.getMaxDryMatter() + '%',
                        critera: this.getDryMatter() <= this.getMaxDryMatter() && this.getDryMatter() >= this.getMinDryMatter(),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t("Extremely wet diets may increase rumen fermentability and cause excessive acid load"),
                        critera: this.getDryMatter() < this.getMinDryMatter(),
                        mood: "text-danger"
                    },
                    {
                        response: this.app.$t("Drier diets are at greater risk for sorting, target is 45% - 55%."),
                        critera: this.getDryMatter() > this.getMaxDryMatter(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "rumination_time_per_day",
                title: this.app.$t("Rumination Time per Day (minutes)"),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: this.getRuminationTimePerDay() >= this.getMinRuminationTimePerDay(),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t("Inadequate rumination increases the risk of acidosis."),
                        critera: this.getRuminationTimePerDay() < this.getMinRuminationTimePerDay(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "tmr_top_screen",
                title: this.app.$t("TMR Top Screen %"),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: (this.getTmrTopScreen() >= this.getMinTmrTopScreen()) && (this.getTmrTopScreen() <= this.getMaxTmrTopScreen()),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t('The ration contains insufficent long fiber which is a risk for ruminal acidosis, ideal range is ' + this.getMinTmrTopScreen() + this.app.$t(' to ') + this.getMaxTmrTopScreen() + '%.'),
                        critera: this.getTmrTopScreen() < this.getMinTmrTopScreen(),
                        mood: "text-danger"
                    },
                    {
                        response: this.app.$t('The ration is at risk of being sorted, ideal range is ') + this.getMinTmrTopScreen() + this.app.$t(' to ') + this.getMaxTmrTopScreen() + '%.',
                        critera: this.getTmrTopScreen() > this.getMaxTmrTopScreen(),
                        mood: "text-danger"
                    }
                ]
            },
            //Nutrition Supply
            {
                key: "forage_ndf",
                title: this.app.$t("Forage NDF %"),
                target: this.getMinForageNdf() + this.app.$t(" - ") + this.getMaxForageNdf(),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: (this.getForageNdf() >= this.getMinForageNdf()) && (this.getForageNdf() <= this.getMaxForageNdf()),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t('Insufficient amounts of physically effective fiber increases risk of low rumen pH.'),
                        critera: this.getForageNdf() < this.getMinForageNdf(),
                        mood: "text-danger"
                    },
                    {
                        // no response for too high in original application
                        response: this.app.$t(''),
                        critera: this.getForageNdf() > this.getMaxForageNdf(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "buffer",
                title: this.app.$t("Buffer"),
                target: this.getMinBuffer() + this.app.$t(" - ") + this.getMaxBuffer(),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: (this.getBuffer() >= this.getMinBuffer()) && (this.getBuffer() <= this.getMaxBuffer()),
                        mood: "text-success"
                    },
                    {
                        // Why are these responses the same?
                        response: this.app.$t('Low rumen pH increases risk of cows developing claw lesions.'),
                        critera: this.getBuffer() < this.getMinBuffer(),
                        mood: "text-danger"
                    },
                    {
                        // Why are these responses the same?
                        response: this.app.$t('Low rumen pH increases risk of cows developing claw lesions.'),
                        critera: this.getBuffer() > this.getMaxBuffer(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "ndf",
                title: this.app.$t("NDF %"),
                target: "≥ " + this.getMinNdf(),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: this.getNdf() >= this.getMinNdf(),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t("Insufficient amounts of physically effective fiber increases risk of low rumen pH."),
                        critera: this.getNdf() < this.getMinNdf(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "nfc",
                title: this.app.$t("NFC %"),
                target: this.getIdealNfcMin() + '-' + this.getIdealNfcMax(),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: this.getNfc() >= this.getIdealNfcMin() && this.getNfc() <= this.getIdealNfcMax(),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t("Excessive amounts of rumen fermentable carbohydrates increases risk of low rumen pH and the development of claw lesions."),
                        critera: this.getNfc() > this.getIdealNfcMax(),
                        mood: "text-danger"
                    },
                    {
                        response: '',
                        critera: this.getNfc() < this.getIdealNfcMin(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "adf",
                title: this.app.$t("ADF %"),
                target: this.getIdealAdfMin() + '-' +  this.getIdealAdfMax(),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: this.getAdf() >= this.getIdealAdfMin() && this.getAdf() <= this.getIdealAdfMax(),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t("Insufficient amounts of physically effective fiber increases risk of low rumen pH."),
                        critera: this.getAdf() < this.getIdealAdfMin(),
                        mood: "text-danger"
                    },
                    {
                        response: '',
                        critera: this.getAdf() > this.getIdealAdfMax(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "biotin",
                title: this.app.$t("Biotin"),
                target: this.getMinBiotin(),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: this.getBiotin() >= this.getMinBiotin(),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t("Biotin has been shown to reduce incidence of claw lesions."),
                        critera: this.getBiotin() < this.getMinBiotin(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "cp",
                title: this.app.$t("CP %"),
                target: this.getMinCp() + this.app.$t(" - ") + this.getMaxCp(),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: (this.getCp() >= this.getMinCp()) && (this.getCp() <= this.getMaxCp()),
                        mood: "text-success"
                    },
                    {
                        // no response for too low in original application
                        response: this.app.$t(''),
                        critera: this.getCp() < this.getMinCp(),
                        mood: "text-danger"
                    },
                    {
                        response: this.app.$t('Limited data shows that feeding large amounts of rumen degradable protein may increase incidence of claw lesions.'),
                        critera: this.getCp() > this.getMaxCp(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "ca",
                title: this.app.$t("CA %"),
                target: this.getMinCa() + this.app.$t(" - ") + this.getMaxCa(),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: (this.getCa() >= this.getMinCa()) && (this.getCa() <= this.getMaxCa()),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t('Calcium plays a critical role in keratin formation.'),
                        critera: this.getCa() < this.getMinCa(),
                        mood: "text-danger"
                    },
                    {
                        // no response for too high in original application
                        response: this.app.$t(''),
                        critera: this.getCa() > this.getMaxCa(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "p",
                title: this.app.$t("P %"),
                target: this.getMinP() + this.app.$t(" - ") + this.getMaxP(),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: (this.getP() >= this.getMinP()) && (this.getP() <= this.getMaxP()),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t('Phosphorus is needed for proper bone development.'),
                        critera: this.getP() < this.getMinP(),
                        mood: "text-danger"
                    },
                    {
                        // no response for too high in original application
                        response: this.app.$t(''),
                        critera: this.getP() > this.getMaxP(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "k",
                title: this.app.$t("K %"),
                target: this.getMinK() + this.app.$t(" - ") + this.getMaxK(),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: (this.getK() >= this.getMinK()) && (this.getK() <= this.getMaxK()),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t('Feeding well formulated diets help minimize claw lesions.'),
                        critera: this.getK() < this.getMinK(),
                        mood: "text-danger"
                    },
                    {
                        // no response for too high in original application
                        response: this.app.$t(''),
                        critera: this.getK() > this.getMaxK(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "mg",
                title: this.app.$t("Mg %"),
                target: this.getMinMg() + this.app.$t(" - ") + this.getMaxMg(),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: (this.getMg() >= this.getMinMg()) && (this.getMg() <= this.getMaxMg()),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t('Feeding well formulated diets help minimize claw lesions.'),
                        critera: this.getMg() < this.getMinMg(),
                        mood: "text-danger"
                    },
                    {
                        // no response for too high in original application
                        response: this.app.$t(''),
                        critera: this.getMg() > this.getMaxMg(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "s",
                title: this.app.$t("S %"),
                target: this.getMinS() + this.app.$t(" - ") + this.getMaxS(),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: (this.getS() >= this.getMinS()) && (this.getS() <= this.getMaxS()),
                        mood: "text-success"
                    },
                    {
                        // no response for too low in original application
                        response: this.app.$t(''),
                        critera: this.getS() < this.getMinS(),
                        mood: "text-danger"
                    },
                    {
                        response: this.app.$t('High levels of sulfur reduce copper and selenium availability.'),
                        critera: this.getS() > this.getMaxS(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "zn_ppm",
                title: this.app.$t("Zn ppm"),
                target: this.getMinZnPpm() + this.app.$t(" - ") + this.getMaxZnPpm(),
                // type: this.getZnPpmSelect() === 'supplemental' ? this.app.$t('Supplemental') : this.app.$t('Total Dietary'),
                type: this.getSelectSupplyLabel(this.getZnPpmSelect()),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: (this.getZnPpm() >= this.getMinZnPpm()) && (this.getZnPpm() <= this.getMaxZnPpm()),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t('Zinc has critical roles in claw horn formation.'),
                        critera: this.getZnPpm() < this.getMinZnPpm(),
                        mood: "text-danger"
                    },
                    {
                        // no response for too low in original application
                        response: this.app.$t(''),
                        critera: this.getZnPpm() > this.getMaxZnPpm(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "cu_ppm",
                title: this.app.$t("Cu ppm"),
                target: this.getMinCuPpm() + this.app.$t(" - ") + this.getMaxCuPpm(),
                // type: this.getCuPpmSelect() === 'supplemental' ? this.app.$t('Supplemental') : this.app.$t('Total Dietary'),
                type: this.getSelectSupplyLabel(this.getCuPpmSelect()),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: (this.getCuPpm() >= this.getMinCuPpm()) && (this.getCuPpm() <= this.getMaxCuPpm()),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t('Copper has a critical role in horn and connective tissue formation.'),
                        critera: this.getCuPpm() < this.getMinCuPpm(),
                        mood: "text-danger"
                    },
                    {
                        response: this.app.$t('Supplementing high levels of copper can result in copper toxicosis.'),
                        critera: this.getCuPpm() > this.getMaxCuPpm(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "mn_ppm",
                title: this.app.$t("Mn Ppm"),
                target: this.getMinMnPpm() + this.app.$t(" - ") + this.getMaxMnPpm(),
                type: this.getSelectSupplyLabel(this.getMnPpmSelect()),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: (this.getMnPpm() >= this.getMinMnPpm()) && (this.getMnPpm() <= this.getMaxMnPpm()),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t('Manganese has a critical role in cartilage formation.'),
                        critera: this.getMnPpm() < this.getMinMnPpm(),
                        mood: "text-danger"
                    },
                    {
                        // no response for too low in original application
                        response: this.app.$t(''),
                        critera: this.getMnPpm() > this.getMaxMnPpm(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "co_ppm",
                title: this.app.$t("Co ppm"),
                target: this.getMinCoPpm() + this.app.$t(" - ") + this.getMaxCoPpm(),
                type: this.getSelectSupplyLabel(this.getCoPpmSelect()),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: (this.getCoPpm() >= this.getMinCoPpm()) && (this.getCoPpm() <= this.getMaxCoPpm()),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t('Vitamin B12 deficiency has been associated with increased incidence of claw lesions.'),
                        critera: this.getCoPpm() < this.getMinCoPpm(),
                        mood: "text-danger"
                    },
                    {
                        // no response for too low in original application
                        response: this.app.$t(''),
                        critera: this.getCoPpm() > this.getMaxCoPpm(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "i_ppm",
                title: this.app.$t("I ppm"),
                target: this.getMinIPpm() + this.app.$t(" - ") + this.getMaxIPpm(),
                type: this.getSelectSupplyLabel(this.getIPpmSelect()),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: (this.getIPpm() >= this.getMinIPpm()) && (this.getIPpm() <= this.getMaxIPpm()),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t('Feeding insufficient amounts of iodine may increase the incidence of foot rot and infertility.'),
                        critera: this.getIPpm() < this.getMinIPpm(),
                        mood: "text-danger"
                    },
                    {
                        response: this.app.$t('Feeding excessive amounts of iodine may result in increased incidence of respiratory problems and excessive levels of iodine in milk.'),
                        critera: this.getIPpm() > this.getMaxIPpm(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "se_ppm",
                title: this.app.$t("Se ppm"),
                target: this.getMinSePpm() + this.app.$t(" - ") + this.getMaxSePpm(),
                type: this.getSelectSupplyLabel(this.getSePpmSelect()),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: (this.getSePpm() >= this.getMinSePpm()) && (this.getSePpm() <= this.getMaxSePpm()),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t('Feeding insufficient amounts of selenium increases oxidative stress as it plays an important role in protecting both the intra and extra-cellular lipid membranes against oxidative damage.'),
                        critera: this.getSePpm() < this.getMinSePpm(),
                        mood: "text-danger"
                    },
                    {
                        response: this.app.$t('Excessive selenium supplementation has been shown to result in increased lameness, sore feet and deformed claws.'),
                        critera: this.getSePpm() > this.getMaxSePpm(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "fe_ppm",
                title: this.app.$t("Fe ppm"),
                target: this.getMinFePpm() + this.app.$t(" - ") + this.getMaxFePpm(),
                type: this.getSelectSupplyLabel(this.getFePpmSelect()),
                options: [{
                        response: this.app.$t("Good!"),
                        critera: (this.getFePpm() >= this.getMinFePpm()) && (this.getFePpm() <= this.getMaxFePpm()),
                        mood: "text-success"
                    },
                    {
                        // no response for too low in original application
                        response: this.app.$t(''),
                        critera: this.getFePpm() < this.getMinFePpm(),
                        mood: "text-danger"
                    },
                    {
                        response: this.app.$t('Diets containing more than ') + this.getMaxFePpm() + this.app.$t(' ppm Fe have been shown to have reduced availability of Zn, Cu and Mn.'),
                        critera: this.getFePpm() > this.getMaxFePpm(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "mo_ppm",
                title: this.app.$t("Mo ppm"),
                target: this.app.$t("Cu:Mo > 6:1"),
                options: [{
                        response: this.app.$t("Your Cu:Mo is ") + this.getMoNutritionRatio() + this.app.$t(":1 In diets with a Cu:Mo < 6:1, Cu availability is reduced."),
                        critera: 6 >= this.getMoNutritionRatio(),
                        mood: "text-danger"
                    },
                    {
                        response: this.app.$t("Your Cu:Mo is ") + this.getMoNutritionRatio() + this.app.$t(":1"),
                        critera: 6 < this.getMoNutritionRatio(),
                        mood: "text-success"
                    }
                ]
            },
            // Zinpro Performance Minerals
            {
                key: "four_plex_c",
                title: "Zinpro 4-Plex C",
                target: this.getMin4PlexC(),
                options: [{
                        response: this.app.$t(""),
                        critera: this.get4PlexC() >= this.getMin4PlexC(),
                        mood: "text-success"
                    },
                    {
                        response: this.getZinproPerformanceMaterialsLowMessage(),
                        critera: this.get4PlexC() < this.getMin4PlexC(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "availa_4",
                title: "Zinpro Availa 4",
                target: this.getMinAvaila4(),
                options: [{
                        response: this.app.$t(""),
                        critera: this.getAvaila4() >= this.getMinAvaila4(),
                        mood: "text-success"
                    },
                    {
                        response: this.getZinproPerformanceMaterialsLowMessage(),
                        critera: this.getAvaila4() < this.getMinAvaila4(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "availa_cu_100",
                title: "Zinpro Propath Cu",
                target: this.getMinAvailaCu100(),
                options: [{
                        response: this.app.$t(""),
                        critera: this.getAvailaCu100() >= this.getMinAvailaCu100(),
                        mood: "text-success"
                    },
                    {
                        response: this.getZinproPerformanceMaterialsLowMessage(),
                        critera: this.getAvailaCu100() < this.getMinAvailaCu100(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "availa_mn_80",
                title: "Zinpro Availa MN 80",
                target: this.getMinAvailaMn80(),
                options: [{
                        response: this.app.$t(""),
                        critera: this.getAvailaMn80() >= this.getMinAvailaMn80(),
                        mood: "text-success"
                    },
                    {
                        response: this.getZinproPerformanceMaterialsLowMessage(),
                        critera: this.getAvailaMn80() < this.getMinAvailaMn80(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "availa_zn_120",
                title: "Zinpro Availa Zn 120",
                target: this.getMinAvailaZn120(),
                options: [{
                        response: this.app.$t(""),
                        critera: this.getAvailaZn120() >= this.getMinAvailaZn120(),
                        mood: "text-success"
                    },
                    {
                        response: this.getZinproPerformanceMaterialsLowMessage(),
                        critera: this.getAvailaZn120() < this.getMinAvailaZn120(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "availa_zn_40",
                title: "Zinpro Availa Zn 40",
                target: this.getMinAvailaZn40(),
                options: [{
                        response: this.app.$t(""),
                        critera: this.getAvailaZn40() >= this.getMinAvailaZn40(),
                        mood: "text-success"
                    },
                    {
                        response: this.getZinproPerformanceMaterialsLowMessage(),
                        critera: this.getAvailaZn40() < this.getMinAvailaZn40(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "availa_plus",
                title: "Zinpro Availa Plus",
                target: this.getMinAvailaPlus(),
                options: [{
                        response: this.app.$t("Target is 0.1% of DMI in kg"),
                        critera: this.getAvailaPlus() >= this.getMinAvailaPlus(),
                        mood: "text-success"
                    },
                    {
                        response: this.app.$t("Target is 0.1% of DMI in kg"), // this.getZinproPerformanceMaterialsLowMessage(),
                        critera: this.getAvailaPlus() < this.getMinAvailaPlus(),
                        mood: "text-success" // "text-danger"
                    }
                ]
            },
            {
                key: "availa_dairy",
                title: "Availa-Dairy",
                target: this.getMinAvailaDairy(),
                options: [{
                    response: this.app.$t("Target is 0.06% of DMI in kg"),
                    critera: this.getAvailaDairy() >= this.getMinAvailaDairy(),
                    mood: "text-success"
                },
                    {
                        response: this.app.$t("Target is 0.06% of DMI in kg"), // this.getZinproPerformanceMaterialsLowMessage(),
                        critera: this.getAvailaDairy() < this.getMinAvailaDairy(),
                        mood: "text-success" // "text-danger"
                    }
                ]
            },
            {
                key: "copro_25",
                title: "Copro 25",
                target: this.getMinCopro25(),
                options: [{
                        response: this.app.$t(""),
                        critera: this.getCopro25() >= this.getMinCopro25(),
                        mood: "text-success"
                    },
                    {
                        response: this.getZinproPerformanceMaterialsLowMessage(),
                        critera: this.getCopro25() < this.getMinCopro25(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "cuplex_100",
                title: "CuPlex 100",
                target: this.getMinCuplex100(),
                options: [{
                        response: this.app.$t(""),
                        critera: this.getCuplex100() >= this.getMinCuplex100(),
                        mood: "text-success"
                    },
                    {
                        response: this.getZinproPerformanceMaterialsLowMessage(),
                        critera: this.getCuplex100() < this.getMinCuplex100(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "cuplex_50",
                title: "CuPlex 50",
                target: this.getMinCuplex50(),
                options: [{
                        response: this.app.$t(""),
                        critera: this.getCuplex50() >= this.getMinCuplex50(),
                        mood: "text-success"
                    },
                    {
                        response: this.getZinproPerformanceMaterialsLowMessage(),
                        critera: this.getCuplex50() < this.getMinCuplex50(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "manpro_160",
                title: "Manpro 160",
                target: this.getMinManpro160(),
                options: [{
                        response: this.app.$t(""),
                        critera: this.getManpro160() >= this.getMinManpro160(),
                        mood: "text-success"
                    },
                    {
                        response: this.getZinproPerformanceMaterialsLowMessage(),
                        critera: this.getManpro160() < this.getMinManpro160(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "zinpro_120",
                title: "Zinpro 120",
                target: this.getMinZinpro120(),
                options: [{
                        response: this.app.$t(""),
                        critera: this.getZinpro120() >= this.getMinZinpro120(),
                        mood: "text-success"
                    },
                    {
                        response: this.getZinproPerformanceMaterialsLowMessage(),
                        critera: this.getZinpro120() < this.getMinZinpro120(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "zinpro_180",
                title: "Zinpro 180",
                target: this.getMinZinpro180(),
                options: [{
                        response: this.app.$t(""),
                        critera: this.getZinpro180() >= this.getMinZinpro180(),
                        mood: "text-success"
                    },
                    {
                        response: this.getZinproPerformanceMaterialsLowMessage(),
                        critera: this.getZinpro180() < this.getMinZinpro180(),
                        mood: "text-danger"
                    }
                ]
            },
            {
                key: "zinpro_40",
                title: "Zinpro 40",
                target: this.getMinZinpro40(),
                options: [{
                        response: this.app.$t(""),
                        critera: this.getZinpro40() >= this.getMinZinpro40(),
                        mood: "text-success"
                    },
                    {
                        response: this.getZinproPerformanceMaterialsLowMessage(),
                        critera: this.getZinpro40() < this.getMinZinpro40(),
                        mood: "text-danger"
                    }
                ]
            }
        ];


        for (var i = 0; i < messages.length; i++) {
            if (messages[i].key === messageKey) {
                for (var j = 0; j < messages[i].options.length; j++) {
                    if (messages[i].options[j].critera === true) {
                        return {
                            title: messages[i].title,
                            target: messages[i].target,
                            type: messages[i].type,
                            feedback: messages[i].options[j]
                        };
                    }
                }
            }
        }

        return {
            title: '',
            feedback: ''
        };
    }

    getZinproPerformanceMaterialsLowMessage() {
        return this.app.$t('Feeding less than the recommended amount will result in less than expected improvements in feet, fertility and immune function.');
    }

    //Feed Access
    getFeedSpace(roundPoint) {
        if (this.assessor.data.feed_space === undefined || this.assessor.data.feed_space === null) {
            return this.assessor.data.feed_space;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.feed_space.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.feed_space)
                .from('cm')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm')))
            .toDecimal(roundPoint);
    }

    setFeedSpace(newValue) {
        if (newValue === '') {
            return this.assessor.data.feed_space = null;
        }

        this.assessor.data.feed_space = parseFloat(convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'cm'))
            .to('cm'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getMinFeedSpace() {
        let min = null;

        if (this.getFeedSpace()) {
            min = this.evaluation.getMeasurements() === 'metric' ? 75 : 30; // Default to the non-lactating min

            // If it is a "lactating" group
            if (this.group.getAreMilkCowsInHerd()) {
                min = this.evaluation.getMeasurements() === 'metric' ? 60 : 24; // Reset to the lactating min
            }
        }

        return min;
    }

    getPercentageOfGroupLyingDownChewingCud() {
        return this.assessor.data.percentage_of_group_lying_down_chewing_cud;
    }

    getMinPercentageOfGroupLyingDownChewingCud() {
        return this.minPercentageOfGroupLyingDownChewingCud;
    }

    getTimeOfLyingDownChewingCudAssessment() {
        return this.assessor.data.time_of_lying_down_chewing_cud_assessment;
    }

    getAreThereAnyEmptyFeedBunksAfterMilking() {
        return this.assessor.data.are_there_any_empty_feed_bunks_after_milking;
    }

    getAreGrazingCowsHeldOffPastureForProlongedPeriodsOtherThanMilkingTime() {
        return this.assessor.data.are_grazing_cows_held_off_pasture_for_prolonged_periods_other_than_milking_time;
    }

    // Risk of Acidosis
    getDryMatter() {
        return this.assessor.data.dry_matter;
    }

    getMinDryMatter() {
        return this.minDryMatter;
    }

    getMaxDryMatter() {
        return this.maxDryMatter;
    }

    getTmrTopScreen() {
        return this.assessor.data.tmr_top_screen;
    }

    getMinTmrTopScreen() {
        return this.minTmrTopScreen;
    }

    getMaxTmrTopScreen() {
        return this.maxTmrTopScreen;
    }

    getTmrMiddleScreen() {
        return this.assessor.data.tmr_middle_screen;
    }

    getMinTmrMiddleScreen() {
        return this.minTmrMiddleScreen;
    }

    getMaxTmrMiddleScreen() {
        return this.maxTmrMiddleScreen;
    }

    getTmrBottomScreen() {
        return this.assessor.data.tmr_bottom_screen;
    }

    getMinTmrBottomScreen() {
        return this.minTmrBottomScreen;
    }

    getMaxTmrBottomScreen() {
        return this.maxTmrBottomScreen;
    }

    getTmrPan() {
        return this.assessor.data.tmr_pan;
    }

    getMinTmrPan() {
        return this.minTmrPan;
    }

    getMaxTmrPan() {
        return this.maxTmrPan;
    }

    getRuminationTimePerDay() {
        return this.assessor.data.rumination_time_per_day;
    }

    getMinRuminationTimePerDay() {
        return this.minRuminationTimePerDay;
    }

    getAreFeedDeliveryAndPushupsTimedToMaximizeFeedAccess() {
        return this.assessor.data.are_feed_delivery_and_pushups_timed_to_maximize_feed_access;
    }

    getAreCowsFedMoreThanOfConcentrateGrainInTheParlor() {
        return this.assessor.data.are_cows_fed_more_than_of_concentrate_grain_in_the_parlor;
    }

    getMinAreCowsFedMoreThanOfConcentrateGrainInTheParlor() {
        let roundPoint = 1;
        if (this.evaluation.getMeasurements() === 'imperial') roundPoint = 0;
        return parseFloat(convert(this.minAreCowsFedMoreThanOfConcentrateGrainInTheParlor)
            .from('kg')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'kg')))
            .toDecimal(roundPoint);
    }

    getIsThereAHighlyFermentableCarbohydrateFeedDeliveredSeparateAtAnyTime() {
        return this.assessor.data.is_there_a_highly_fermentable_carbohydrate_feed_delivered_separate_at_any_time;
    }

    getIfRumenocentesisHasBeenPerformedDoesTheBestIndicateAProblemWithSara() {
        return this.assessor.data.if_rumenocentesis_has_been_performed_does_the_best_indicate_a_problem_with_sara;
    }

    getIsACompleteNutrientWorkupRequired() {
        return this.assessor.data.is_a_complete_nutrient_workup_required;
    }

    //Nutrition Supply
    getDryMatterIntake(roundPoint) {
        if (this.assessor.data.dry_matter_intake === undefined || this.assessor.data.dry_matter_intake === null) {
            return this.assessor.data.dry_matter_intake;
        }

        if (roundPoint === false) {
            roundPoint = this.assessor.data.dry_matter_intake.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }

        return parseFloat(convert(this.assessor.data.dry_matter_intake)
                .from('kg')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'kg')))
            .toDecimal(roundPoint);
    }

    setDryMatterIntake(newValue) {
        if (newValue === '') {
            return this.assessor.data.dry_matter_intake = null;
        }

        this.assessor.data.dry_matter_intake = parseFloat(convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'kg'))
            .to('kg'))
            .toDecimal(newValue.countDecimals() + 3);
    }

    getCuPpm() {
        return this.assessor.data.cu_ppm;
    }

    getCuPpmSelect() {
        return this.assessor.data.cu_ppm_select;
    }

    getMinCuPpm() {
        if (this.assessor.data.cu_ppm_select === 'supplemental') {
            return this.minCuPpmSupplemental;
        } else if (this.assessor.data.cu_ppm_select === 'total_dietary') {
            return this.minCuPpmTotal;
        }
        return ''
    }

    getMaxCuPpm() {
        if (this.assessor.data.cu_ppm_select === 'supplemental') {
            return this.maxCuPpmSupplemental;
        } else if (this.assessor.data.cu_ppm_select === 'total_dietary') {
            return this.maxCuPpmTotal;
        }
        return ''
    }

    getMnPpm() {
        return this.assessor.data.mn_ppm;
    }

    getMnPpmSelect() {
        return this.assessor.data.mn_ppm_select;
    }

    getMinMnPpm() {
        if (this.assessor.data.mn_ppm_select === 'supplemental') {
            return this.minMnPpmSupplemental;
        } else if (this.assessor.data.mn_ppm_select === 'total_dietary') {
            return this.minMnPpmTotal;
        }
        return ''
    }

    getMaxMnPpm() {
        if (this.assessor.data.mn_ppm_select === 'supplemental') {
            return this.maxMnPpmSupplemental;
        } else if (this.assessor.data.mn_ppm_select === 'total_dietary') {
            return this.maxMnPpmTotal;
        }
        return ''
    }

    getCoPpm() {
        return this.assessor.data.co_ppm;
    }

    getCoPpmSelect() {
        return this.assessor.data.co_ppm_select;
    }

    getMinCoPpm() {
        return this.minCoPpm
    }

    getMaxCoPpm() {
        return this.maxCoPpm
    }

    getIPpm() {
        return this.assessor.data.i_ppm;
    }

    getIPpmSelect() {
        return this.assessor.data.i_ppm_select;
    }

    getMinIPpm() {
        return this.minIPpm;
    }

    getMaxIPpm() {
        return this.maxIPpm;
    }

    getSePpm() {
        return this.assessor.data.se_ppm;
    }

    getSePpmSelect() {
        return this.assessor.data.se_ppm_select;
    }

    getMinSePpm() {
        return this.minSePpm;
    }

    getMaxSePpm() {
        return this.maxSePpm;
    }

    getFePpm() {
        return this.assessor.data.fe_ppm;
    }

    getFePpmSelect() {
        return this.assessor.data.fe_ppm_select;
    }

    getMinFePpm() {
        return this.minFePpm;
    }

    getMaxFePpm() {
        return this.maxFePpm;
    }

    getMoPpm() {
        return this.assessor.data.mo_ppm;
    }

    // calculation
    getMoNutritionRatio() {

        let recommendation = null;

        if (this.getCuPpm() && this.getMoPpm()) {
            return Number(this.getCuPpm() / this.getMoPpm()).toFixed(2);
        }

        return recommendation
    }

    getBiotin(roundPoint) {

        if (this.assessor.data.biotin === undefined || this.assessor.data.biotin === null) {
            return this.assessor.data.biotin;
        }


        if (roundPoint === false) {
            roundPoint = this.assessor.data.biotin.countDecimals() - 3;
            if (roundPoint < 0) roundPoint = 0;
        }


        return this.assessor.data.biotin;

        // return parseFloat(convert(this.assessor.data.biotin)
            // .from('mg')
            // .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'mg')))
            // .toDecimal(0);
    }

    setBiotin(newValue) {
        if (newValue === '') {
            return this.assessor.data.biotin = null;
        }

        this.assessor.data.biotin = convert(newValue)
            .from(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'mg'))
            .to('mg')
            .toDecimal(0);
    }

    getMinBiotin() {

        // need to get response from client on how to handle this since this field doesn't get converted
        return this.minBiotin;
        // return parseFloat(convert(this.minBiotin)
        //         .from('mg')
        //         .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'mg')))
        //     .toDecimal(0);
    }

    getBuffer(roundPoint) {
        if (this.evaluation.getMeasurements() === 'imperial') {
            return this.assessor.data.buffer_imp
        }
        return this.assessor.data.buffer;
    }

    setBuffer (newValue) {
        if (newValue === '') {
            this.assessor.data.buffer_imp = null
            return this.assessor.data.buffer = null;
        }
        if (this.evaluation.getMeasurements() === 'imperial') {
            this.assessor.data.buffer_imp = newValue.toFixed(1)
            this.assessor.data.buffer = convert(newValue)
              .from('oz')
              .to('g')
              .toFixed(1);
        } else {
            this.assessor.data.buffer = newValue.toFixed(1);
            this.assessor.data.buffer_imp = convert(newValue)
              .from('g')
              .to('oz')
              .toFixed(1);
        }
    }

    getMinBuffer() {
        let roundPoint = 0;
        let mpy = 1
        if (this.evaluation.getMeasurements() === 'imperial') {
            roundPoint = 1;
            mpy = 16
        }
        let output = parseFloat(convert(this.minBuffer)
            .from('g')
            .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'g')))
            .toDecimal(roundPoint);
        return (output * mpy).toDecimal(0)
    }

    getMaxBuffer() {
        let roundPoint = 0;
        let mpy = 1
        if (this.evaluation.getMeasurements() === 'imperial') {
            roundPoint = 1;
            mpy = 16
        }
        let output = parseFloat(convert(this.maxBuffer)
                .from('g')
                .to(this.app.$getEquivalentUnit(this.evaluation.getMeasurements(), 'g')))
            .toDecimal(roundPoint);
        return (output * mpy).toDecimal(0)
    }

    getForageNdf() {
        return this.assessor.data.forage_ndf;
    }

    getMinForageNdf() {
        return this.minForageNdf;
    }

    getMaxForageNdf() {
        return this.maxForageNdf;
    }

    getNfc() {
        return this.assessor.data.nfc;
    }

    getIdealNfc() {
        return this.idealNfc;
    }

    getIdealNfcMin() {
        return this.idealNfcMin;
    }

    getIdealNfcMax() {
        return this.idealNfcMax;
    }

    getNdf() {
        return this.assessor.data.ndf;
    }

    getMinNdf() {
        return this.minNdf;
    }

    getAdf() {
        return this.assessor.data.adf;
    }

    getIdealAdf() {
        return this.idealAdf;
    }

    getIdealAdfMin() {
        return this.idealAdfMin;
    }

    getIdealAdfMax() {
        return this.idealAdfMax;
    }

    getCp() {
        return this.assessor.data.cp;
    }

    getMinCp() {
        return this.minCp;
    }

    getMaxCp() {
        return this.maxCp;
    }

    getCa() {
        return this.assessor.data.ca;
    }

    getMinCa() {
        return this.minCa;
    }

    getMaxCa() {
        return this.maxCa;
    }

    getP() {
        return this.assessor.data.p;
    }

    getMinP() {
        return this.minP;
    }

    getMaxP() {
        return this.maxP;
    }

    getK() {
        return this.assessor.data.k;
    }

    getMinK() {
        return this.minK;
    }

    getMaxK() {
        return this.maxK;
    }

    getMg() {
        return this.assessor.data.mg;
    }

    getMinMg() {
        return this.minMg;
    }

    getMaxMg() {
        return this.maxMg;
    }

    getS() {
        return this.assessor.data.s;
    }

    getMinS() {
        return this.minS;
    }

    getMaxS() {
        return this.maxS;
    }

    getZnPpm() {
        return this.assessor.data.zn_ppm;
    }

    getMinZnPpm() {
        return this.minZnPpm;

    }
    getMinZnPpm() {
        if (this.assessor.data.zn_ppm_select === 'supplemental') {
            return this.minZnPpmSupplemental;
        } else if (this.assessor.data.zn_ppm_select === 'total_dietary') {
            return this.minZnPpmTotal;
        }
        return ''
    }

    getMaxZnPpm() {
        if (this.assessor.data.zn_ppm_select === 'supplemental') {
            return this.maxZnPpmSupplemental;
        } else if (this.assessor.data.zn_ppm_select === 'total_dietary') {
            return this.maxZnPpmTotal;
        }
        return ''
    }

    getSelectSupplyLabel (value) {
        switch (value) {
            case 'supplemental':
                return this.app.$t('Supplemental')
            case 'total_dietary':
                return this.app.$t('Total Dietary')
            default:
              return ''
        }
    }

    getZnPpmSelect() {
        return this.assessor.data.zn_ppm_select;
    }

    //zinpro performace minerals

    get4PlexC() {
        return this.assessor.data.four_plex_c
    }

    set4PlexC(newValue) {
        if (newValue === '') {
            return this.assessor.data.four_plex_c = null
        }
        this.assessor.data.four_plex_c = newValue
    }

    getMin4PlexC() {
        return this.min4PlexC
    }

    getAvaila4() {
        return this.assessor.data.availa_4;
    }

    setAvaila4(newValue) {
        if (newValue === '') {
            return this.assessor.data.availa_4 = null;
        }
        this.assessor.data.availa_4 = newValue
    }

    getMinAvaila4() {
        return this.minAvaila4
    }

    getAvailaCu100() {
        return this.assessor.data.availa_cu_100
    }

    setAvailaCu100(newValue) {
        if (newValue === '') {
            return this.assessor.data.availa_cu_100 = null
        }
        this.assessor.data.availa_cu_100 = newValue
    }

    getMinAvailaCu100() {
        return this.minAvailaCu100
    }

    getAvailaMn80() {
        return this.assessor.data.availa_mn_80
    }

    setAvailaMn80(newValue) {
        if (newValue === '') {
            return this.assessor.data.availa_mn_80 = null
        }
        this.assessor.data.availa_mn_80 = newValue
    }

    getMinAvailaMn80() {
        return this.minAvailaMn80
    }

    getAvailaZn120() {
        return this.assessor.data.availa_zn_120
    }

    setAvailaZn120(newValue) {
        if (newValue === '') {
            return this.assessor.data.availa_zn_120 = null
        }
        this.assessor.data.availa_zn_120 = newValue
    }

    getMinAvailaZn120() {
        return this.minZn120
    }

    getAvailaZn40() {
        return this.assessor.data.availa_zn_40
    }

    setAvailaZn40(newValue) {
        if (newValue === '') {
            return this.assessor.data.availa_zn_40 = null
        }
        this.assessor.data.availa_zn_40 = newValue
    }

    getMinAvailaZn40() {
        return this.minZn40
    }

    getAvailaPlus() {
        return this.assessor.data.availa_plus
    }

    setAvailaPlus(newValue) {
        if (newValue === '') {
            return this.assessor.data.availa_plus = null
        }
        this.assessor.data.availa_plus = newValue
    }

    getMinAvailaPlus() {
        if (this.assessor.data.dry_matter_intake !== null) {
           return Number(this.assessor.data.dry_matter_intake).toFixed(1)
        }
        return ''
    }

    getAvailaDairy() {
        return this.assessor.data.availa_dairy
    }

    setAvailaDairy(newValue) {
        if (newValue === '') {
            return this.assessor.data.availa_dairy = null
        }
        this.assessor.data.availa_dairy = newValue
    }

    getMinAvailaDairy() {
        if (this.assessor.data.dry_matter_intake !== null) {
            return Number(this.assessor.data.dry_matter_intake * 0.6).toFixed(1)
        }
        return ''
    }

    getCopro25() {
        return this.assessor.data.copro_25
    }

    setCopro25(newValue) {
        if (newValue === '') {
            return this.assessor.data.copro_25 = null
        }
        return this.assessor.data.copro_25 = newValue
    }

    getMinCopro25() {
        return this.minCopro25
    }

    getCuplex100() {
        return this.assessor.data.cuplex_100
    }

    setCuplex100(newValue) {
        if (newValue === '') {
            return this.assessor.data.cuplex_100 = null
        }
        this.assessor.data.cuplex_100 = newValue
    }

    getMinCuplex100() {
        return this.minCuPlex100
    }

    getCuplex50() {
        return this.assessor.data.cuplex_50
    }

    setCuplex50(newValue) {
        if (newValue === '') {
            return this.assessor.data.cuplex_50 = null
        }
        this.assessor.data.cuplex_50 = newValue
    }

    getMinCuplex50() {
        return this.minCuPlex50
    }

    getManpro160() {
        return this.assessor.data.manpro_160
    }

    setManpro160(newValue) {
        if (newValue === '') {
            return this.assessor.data.manpro_160 = null
        }
        this.assessor.data.manpro_160 = newValue
    }

    getMinManpro160() {
        return this.minManpro160
    }

    getZinpro120() {
        return this.assessor.data.zinpro_120
    }

    setZinpro120(newValue) {
        if (newValue === '') {
            return this.assessor.data.zinpro_120 = null
        }
        this.assessor.data.zinpro_120 = newValue
    }

    getMinZinpro120() {
        return this.minZinpro120
    }

    getZinpro180() {
        return this.assessor.data.zinpro_180
    }

    setZinpro180(newValue) {
        if (newValue === '') {
            return this.assessor.data.zinpro_180 = null;
        }
        this.assessor.data.zinpro_180 = newValue
    }

    getMinZinpro180() {
        return this.minZinpro180
    }

    getZinpro40() {
        return this.assessor.data.zinpro_40
    }

    setZinpro40(newValue) {
        if (newValue === '') {
            return this.assessor.data.zinpro_40 = null;
        }
        this.assessor.data.zinpro_40 = newValue
    }

    getMinZinpro40() {
        return this.minZinpro40
    }

}

export default NutritionAndFeeding;
