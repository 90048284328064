const Table = require('../../Misc/Table');

export const tieStallStanchion = function (app, report, evaluation, group, assessor) {

    let slide = report.pptx.addSlide('Slide master');

    let titleY = 3.1;
    let tableY = 3.5;

    const standardCategories = [{
        text: app.$t('Question'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Answer'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Comment'),
        options: report.styleOptions.tableHeader
    }];
    const dimensionsCategories = [{
        text: app.$t('Dimensions'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Your Stall'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Target Stall'),
        options: report.styleOptions.tableHeader
    }, {
        text: app.$t('Comment'),
        options: report.styleOptions.tableHeader
    }];
    let topSectionObj = [];
    let dimensionsObj = [];

    let resultMessage;

    slide.addText(
        app.$t('Tie Stall/Stanchion Assessor') + app.$t(' - ') + group.getName(), report.styleOptions.slideHeader
    );

    if (assessor.getStallSurfaceValue() !== null) {
        topSectionObj.push([{
                text: app.$t('Stall Surface'),
            },
            {
                text: app.$numberFormat(assessor.getStallSurface()),
            },
            {
                text: assessor.getStallSurfaceRecommendation(),
                options: {
                    color: assessor.getStallSurfaceValue() === 'sand' || assessor.getStallSurfaceValue() === 'manure_solids' || assessor.getStallSurfaceValue() === 'straw' ? '249824' : 'a40000',
                    align: 'left',
                    fill: 'efefef'
                }
            }
        ]);
        assessor.getStallSurfaceValue() === 'sand' || assessor.getStallSurfaceValue() === 'manure_solids' || assessor.getStallSurfaceValue() === 'straw' ? tableY += .4 : tableY += .6;
        assessor.getStallSurfaceValue() === 'sand' || assessor.getStallSurfaceValue() === 'manure_solids' || assessor.getStallSurfaceValue() === 'straw' ? titleY += .4 : titleY += .6;
    }

    if (group.getCowSizeLabel() !== null && group.getCowSizeLabel() !== '') {
        topSectionObj.push([{
                text: app.$t('Average Cow Size') + ' (' + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'kg')) + ')'
            },
            {
                text: app.$numberFormat(group.getCowSizeLabel()) + app.$t(app.$getEquivalentUnit(evaluation.getMeasurements(), 'kg'))
            },
            {
                text: '',
                options: {
                    fill: 'efefef'
                }
            }
        ]);
        tableY += .4;
        titleY += .4;
    }

    if (assessor.getDoesTheStallPassTheKneeDropTest() !== null) {
        topSectionObj.push([{
                text: app.$t('Does the stall pass the knee-drop test?')
            },
            {
                text: assessor.getDoesTheStallPassTheKneeDropTest() === 1 ? app.$t('Yes') : assessor.getDoesTheStallPassTheKneeDropTest() === 0 ? app.$t('No') : app.$t('N/A')
            },
            {
                text: assessor.getDoesTheStallPassTheKneeDropTest() === 0 ? app.$t('Surface cushion is one of the most important aspects of the stall and amount of cushioning will affect stall lying times. Inadequate stall cushioning will reduce stall lying times. The surface should be soft and moldable from the front of the stall to the back of the stall.') : assessor.getDoesTheStallPassTheKneeDropTest() === 1 ? app.$t('Good!') : '',
                options: {
                    color: assessor.getDoesTheStallPassTheKneeDropTest() === 0 ? 'a40000' : '249824',
                    align: 'left',
                    fill: 'efefef'
                }
            },
        ]);
        assessor.getDoesTheStallPassTheKneeDropTest() === 0 ? tableY += 1.5 : tableY += .4;
        assessor.getDoesTheStallPassTheKneeDropTest() === 0 ? titleY += 1.5 : titleY += .4;
    }

    if (topSectionObj.length > 0) {
        slide.addText(
            app.$t('Your Operation'), {
                x: 0.5,
                y: 2.4,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide, standardCategories, topSectionObj, report, group, 'Tie Stall/Stanchion Assessor', {
            y: 2.7,
            w: 7.5,
            margin: 5,
            color: '000000',
            autoPage: true,
            autoPageRepeatHeader: true,
            newSlideStartY: 2.2,
            colW: [3.25, 1.25, 3],
            valign: 'middle',
            align: 'center',
            fontFace: 'Arial'
        });
    }

    //POPULATE Dimensions OBJECT HERE
    if (assessor.getStallLength() !== null) {
        resultMessage = assessor.getResultMessage('stall_length');
        dimensionsObj.push([{
            text: resultMessage.title,
        }, {
            text: app.$numberFormat(assessor.getStallLength()),
        }, {
            text: resultMessage.target
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-success' ? '249824' : 'a40000',
                align: 'left',
                fill: 'efefef',
            }
        }]);
    }

    if (assessor.getStallWidth() !== null) {
        resultMessage = assessor.getResultMessage('stall_width');
        dimensionsObj.push([{
            text: resultMessage.title,
        }, {
            text: app.$numberFormat(assessor.getStallWidth()),
        }, {
            text: resultMessage.target
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-success' ? '249824' : 'a40000',
                align: 'left',
                fill: 'efefef',
            }
        }]);
    }

    if (assessor.getHeightOfFeedCurbBunkSide() !== null) {
        resultMessage = assessor.getResultMessage('height_of_feed_curb_bunk_side');
        dimensionsObj.push([{
            text: resultMessage.title
        }, {
            text: app.$numberFormat(assessor.getHeightOfFeedCurbBunkSide())
        }, {
            text: resultMessage.target
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getHeightOfFeedCurbStallSide() !== null) {
        resultMessage = assessor.getResultMessage('height_of_feed_curb_stall_side');
        dimensionsObj.push([{
            text: resultMessage.title
        }, {
            text: app.$numberFormat(assessor.getHeightOfFeedCurbStallSide())
        }, {
            text: resultMessage.target
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getHeightOfHeadrailAboveStallSurface() !== null) {
        resultMessage = assessor.getResultMessage('height_of_headrail_above_stall_surface');
        dimensionsObj.push([{
            text: resultMessage.title
        }, {
            text: app.$numberFormat(assessor.getHeightOfHeadrailAboveStallSurface())
        }, {
            text: resultMessage.target
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getLengthOfChain() !== null) {
        resultMessage = assessor.getResultMessage('length_of_chain');
        dimensionsObj.push([{
            text: resultMessage.title
        }, {
            text: app.$numberFormat(assessor.getLengthOfChain())
        }, {
            text: resultMessage.target
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getHeightOfDrinkingCupFloorAboveStallSurface() !== null) {
        resultMessage = assessor.getResultMessage('height_of_drinking_cup_floor_above_stall_surface');
        dimensionsObj.push([{
            text: resultMessage.title
        }, {
            text: app.$numberFormat(assessor.getHeightOfDrinkingCupFloorAboveStallSurface())
        }, {
            text: resultMessage.target
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getHeadrailToRearCurb() !== null) {
        resultMessage = assessor.getResultMessage('headrail_to_rear_curb');
        dimensionsObj.push([{
            text: resultMessage.title
        }, {
            text: app.$numberFormat(assessor.getHeadrailToRearCurb())
        }, {
            text: resultMessage.target
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getRearEdgeOfDividerToCurb() !== null) {
        resultMessage = assessor.getResultMessage('rear_edge_of_divider_to_curb');
        dimensionsObj.push([{
            text: resultMessage.title
        }, {
            text: app.$numberFormat(assessor.getRearEdgeOfDividerToCurb())
        }, {
            text: resultMessage.target
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getDistanceOfTrainersFromRearCurb() !== null) {
        resultMessage = assessor.getResultMessage('distance_of_trainers_from_rear_curb');
        dimensionsObj.push([{
            text: resultMessage.title
        }, {
            text: app.$numberFormat(assessor.getDistanceOfTrainersFromRearCurb())
        }, {
            text: resultMessage.target
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getHeightOfTrainerAboveWither() !== null) {
        resultMessage = assessor.getResultMessage('height_of_trainer_above_wither');
        dimensionsObj.push([{
            text: resultMessage.title
        }, {
            text: app.$numberFormat(assessor.getHeightOfTrainerAboveWither())
        }, {
            text: resultMessage.target
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-danger' ? 'a40000' : '249824',
                align: 'left',
                fill: 'efefef'
            }
        }, ]);
    }

    if (assessor.getIsThereAnObstructionAboveDrinkingCups() !== null) {
        dimensionsObj.push([{
                text: app.$t('Is there an obstruction above drinking cups?')
            },
            {
                text: assessor.getIsThereAnObstructionAboveDrinkingCups() === 1 ? app.$t('Yes') : assessor.getIsThereAnObstructionAboveDrinkingCups() === 0 ? app.$t('No') : app.$t('N/A')
            }, {
                text: ''
            }, {
                text: assessor.getIsThereAnObstructionAboveDrinkingCups() === 1 ? app.$t('Remove obstruction above drinking cups.') : assessor.getIsThereAnObstructionAboveDrinkingCups() === 0 ? app.$t('Good!') : '',
                options: {
                    color: assessor.getIsThereAnObstructionAboveDrinkingCups() === 1 ? 'a40000' : '249824',
                    align: 'left',
                    fill: 'efefef'
                }
            },
        ]);
    }

    if (assessor.getIsThereABobZoneObstruction() !== null) {
        dimensionsObj.push([{
                text: app.$t('Is there a bob-zone obstruction?')
            },
            {
                text: assessor.getIsThereABobZoneObstruction() === 1 ? app.$t('Yes') : assessor.getIsThereABobZoneObstruction() === 0 ? app.$t('No') : app.$t('N/A')
            }, {
                text: ''
            }, {
                text: assessor.getIsThereABobZoneObstruction() === 1 ? app.$t('Consider removing the obstruction in the lunge area.') : assessor.getIsThereABobZoneObstruction() === 0 ? app.$t('Good!') : '',
                options: {
                    color: assessor.getIsThereABobZoneObstruction() === 1 ? 'a40000' : '249824',
                    align: 'left',
                    fill: 'efefef'
                }
            },
        ]);
    }

    if (assessor.getBeddingDepth() !== null && (assessor.getStallSurfaceValue() !== 'manure_solids' && assessor.getStallSurfaceValue() !== 'other' && assessor.getStallSurfaceValue() !== 'mats')) {
        resultMessage = assessor.getResultMessage('bedding_depth');
        dimensionsObj.push([{
            text: resultMessage.title
        }, {
            text: app.$numberFormat(assessor.getBeddingDepth())
        }, {
            text: resultMessage.target
        }, {
            text: resultMessage.feedback.response,
            options: {
                color: resultMessage.feedback.mood === 'text-success' ? '249824' : 'a40000',
                align: 'left',
                fill: 'efefef',
            }
        }]);
    }

    if (dimensionsObj.length > 0) {
        titleY += .7
        tableY += .7
        slide.addText(
            app.$t('Dimensions'), {
                x: 0.5,
                y: titleY,
                ...report.styleOptions.subheaderBlue
            }
        );

        Table.create(slide, dimensionsCategories, dimensionsObj, report, group, 'Tie Stall/Stanchion Assessor', {
            ...report.styleOptions.tableOptions,
            y: tableY,
            colW: [2, 1.25, 1.25, 3]
        });

    }

    return true;

}
