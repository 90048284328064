<!-- @format -->

<template>
  <div>
    <div class="loading" v-show="!isLoaded">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
    <div
      class="container main-container main-container--accordion"
      v-show="isLoaded"
    >
      <div class="row">
        <div class="col-sm-16">
          <h1 class="h1 mb-3">{{ "Customers" | translate }}</h1>
        </div>
        <div
          class="col-sm-8 d-flex justify-content-end align-items-center"
        >
          <router-link
            to="/admin/invite-customer/"
            class="btn btn-primary btn-primary--thin btn-primary--thin--short pull-right btn-full--sm mb-2 mb-sm-0"
            >{{ "Invite New Customer" | translate }}</router-link
          >
        </div>
      </div>

      <div
        v-show="error === true"
        class="alert alert-primary mt-0 mb-3"
        role="alert"
      >
        <span v-show="errorCode === 'user-deleted'">{{
          "User has been deleted!" | translate
        }}</span>
      </div>

      <div
        v-show="success === true"
        class="alert alert-primary mt-0 mb-3"
        role="alert"
      >
        <span v-show="successCode === 'user-created'">{{
          "User has been created!" | translate
        }}</span>
      </div>

      <div class="mt-4 text-center" v-if="mainError === true">
        <h2>
          {{
            "We could not load any customers at this time."
              | translate
          }}
        </h2>
      </div>

      <div v-if="users.length > 0">
        <!-- top scroller -->
        <div
          ref="topScroll"
          class="top-scroll"
          @scroll.passive="handleScroll"
        >
          <div
            :style="{
              width: `${contentWidth}px`,
              height: '12px',
            }"
          />
        </div>
        <!-- top scroller -->

        <div
          class="content"
          ref="content"
          @scroll.passive="handleScroll"
        >
          <table
            class="table table-striped mb-0"
            :style="{
              width: `${contentWidth}px`,
            }"
          >
            <thead class="table-heading">
              <tr ref="tableHeader">
                <!-- <th
                  class="table-heading__text text-white text-center align-middle"
                  scope="col"
                >
                  {{ 'Prefix' | translate }}
                </th>
                <th
                  class="table-heading__text text-white text-center align-middle"
                  scope="col"
                >
                  {{ 'First Name' | translate }}
                </th>
                <th
                  class="table-heading__text text-white text-center align-middle"
                  scope="col"
                >
                  {{ 'Last Name' | translate }}
                </th>
                <th
                  class="table-heading__text text-white text-center align-middle"
                  scope="col"
                >
                  {{ 'Company' | translate }}
                </th>
                <th
                  class="table-heading__text text-white text-center align-middle pointer heading"
                  @click="() => sortByAlphabate('countryCode')"
                >
                  <span class="pointer">
                    {{ 'Country' | translate }}
                    <img
                      class="ml-1 sort"
                      src="@/assets/icons/SortiiconWhite.svg"
                      alt="sort by name"
                    />
                  </span>
                </th>
                <th
                  class="table-heading__text text-white text-center align-middle"
                  scope="col"
                >
                  {{ 'Email' | translate }}
                </th>
                <th
                  class="table-heading__text text-white text-center align-middle"
                  scope="col"
                >
                  {{ 'Role' | translate }}
                </th>
                <th
                  class="table-heading__text text-white text-center align-middle"
                  scope="col"
                >
                  {{ 'Status' | translate }}
                </th> -->
                <th
                  v-for="(
                    {
                      title,
                      method,
                      sortKey,
                      nestedPath = '',
                      classes = '',
                    },
                    index
                  ) in columns"
                  class="table-heading__text text-white text-center align-middle heading pointer"
                  :class="classes"
                  scope="col"
                  @click="() => method(sortKey, nestedPath, index)"
                  :key="title"
                >
                  <span class="pointer display-inline-block">
                    {{ title | translate }}
                    <img
                      class="ml-1 sort invisible"
                      src="@/assets/icons/SortiiconWhite.svg"
                      alt="sort by name"
                      :class="{
                        toggleSortIcon: index === activeSort,
                      }"
                    />
                  </span>
                </th>
                <th
                  class="table-heading__text text-white text-center align-middle"
                  scope="col"
                >
                  {{ "Actions" | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(user, index) in users.filter((user) => user)"
                :key="index"
              >
                <td class="table-item text-center align-middle">
                  {{ user.prefix }}
                </td>
                <td class="table-item text-center align-middle">
                  {{ user.firstName }}
                </td>
                <td class="table-item text-center align-middle">
                  {{ user.lastName }}
                </td>
                <td class="table-item text-center align-middle">
                  <!-- {{ getCompanyNameById(user.company) }} -->
                  {{ user.companyName || "No Company" }}
                </td>
                <td class="table-item text-center align-middle">
                  {{
                    countries[user.countryCode] ||
                    countriesPrimary[user.countryCode] ||
                    "N/A" | translate
                  }}
                </td>
                <td class="table-item text-center align-middle">
                  <a
                    class="table-item__link"
                    :href="'mailto:' + user.email"
                    >{{ user.email }}</a
                  >
                </td>
                <td class="table-item text-center align-middle">
                  <span v-if="user.role === 'external'">{{
                    "Advanced Customer" | translate
                  }}</span>
                  <span v-if="user.role === 'data_entry'">{{
                    "Customer" | translate
                  }}</span>
                  <span v-if="user.role === 'admin'">{{
                    "Admin" | translate
                  }}</span>
                  <span
                    v-if="user.role === 'zinpro_representative'"
                    >{{ "Zinpro Representative" | translate }}</span
                  >
                  <span
                    v-if="user.role === 'intro'"
                    >{{ "Intro" | translate }}</span
                  >
                </td>
                <td class="table-item text-center align-middle">
                  <span v-if="!user.isVerified">{{
                    "Invited" | translate
                  }}</span>
                  <span v-else-if="user.status">{{
                    "Active" | translate
                  }}</span>
                  <span v-else>{{ "Not Active" | translate }}</span>
                </td>
                <td
                  class="table-item text-center align-middle col-lg-3"
                >
                  {{
                    formattedDate(
                      $store.getters.getDate(user.createdAt)
                    )
                  }}
                </td>
                <td
                  class="table-item text-center align-middle col-lg-3"
                >
                  {{
                    user.metadata && user.metadata.lastSignInTime
                      ? formattedDate(
                          $store.getters.getDate(
                            user.metadata.lastSignInTime
                          )
                        )
                      : "N/A"
                  }}
                </td>
                <td class="table-item text-center align-middle">
                  <router-link
                    :to="
                      '/admin/user/' +
                      user._id +
                      `?isFilter=${
                        $route.params.representativeId ? true : false
                      }`
                    "
                    class="table-item__link"
                    >{{ "Edit" | translate }}</router-link
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- end table-responsive -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<script>
import AppHeader from "../../../components/AppHeader";
import AppFooter from "../../../components/AppFooter";
import {
  GET_COMPANIES_BY_USER_ID,
  GET_REPRESENTATIVE_USERS,
} from "../../../store/types";
import { mapActions, mapGetters, mapState } from "vuex";
import { get } from "lodash";
export default {
  components: {
    AppHeader,
    AppFooter,
  },

  data() {
    return {
      representativeCustomer: [],
      contentWidth: 1250,
      isLoaded: true,
      mainError: false,
      error: false,
      errorCode: "",
      success: false,
      successCode: "",
      users: [],
      lastSorted: "desc",
      activeSort: 8,
      columns: [
        {
          title: "Prefix",
          method: this.sortByAlphabate,
          sortKey: "prefix",
        },
        {
          title: "First Name",
          method: this.sortByAlphabate,
          sortKey: "firstName",
        },
        {
          title: "Last Name",
          method: this.sortByAlphabate,
          sortKey: "lastName",
        },
        {
          title: "Company",
          method: this.sortByAlphabate,
          sortKey: "companyName",
        },
        {
          title: "Country",
          method: this.sortByAlphabate,
          sortKey: "countryCode",
        },
        {
          title: "Email",
          method: this.sortByAlphabate,
          sortKey: "email",
        },
        {
          title: "Role",
          method: this.sortByAlphabate,
          sortKey: "role",
        },
        {
          title: "Status",
          method: this.sortByAlphabate,
          sortKey: "status",
        },
        {
          title: "Reg. Date",
          method: this.sortByDate,
          sortKey: "createdAt",
          // classes: 'col-3'
        },
        {
          title: "Last Login",
          method: this.sortByDate,
          sortKey: "lastSignInTime",
          nestedPath: "metadata",
          // classes: 'col-3'
        },
        // { title: 'Actions' },
      ],
    };
  },

  computed: {
    ...mapState({
      allUsers: (state) => state.users.users,
      user: (state) => state.users.user,
      companies: (state) => state.company.companies,
      countries: (state) => state.misc.countries,
      countriesPrimary: (state) => state.misc.countriesPrimary,
      roles: (state) => state.roles,
      locale: (state) => state.i18n.locale,
    }),
    // ...mapGetters('company', [GET_COMPANIES_BY_USER_ID]),
  },
  mounted() {
    setTimeout(() => {
      this.contentWidth = this.$refs?.tableHeader?.clientWidth;
    }, 2000);
  },

  methods: {
    ...mapActions("users", [GET_REPRESENTATIVE_USERS]),
    formattedDate(date) {
      const [d, m, y] = date.split(" ");
      if (d && m && y) {
        return `${d} ${this.$t(m)} ${y}`;
      }
      return date;
    },
    handleScroll(event) {
      console.log(event);
      if (event.target._prevClass === "content") {
        this.$refs.topScroll.scrollLeft =
          this.$refs.content.scrollLeft;
      } else {
        this.$refs.content.scrollLeft =
          this.$refs.topScroll.scrollLeft;
      }
    },
    // ...mapActions('users', [GET_USERS]),
    sortByDate(sortVia, nestedPath = "", index) {
      this.users = this.users.sort((a, b) => {
        const { [sortVia]: timeA = null } = get(
          a,
          nestedPath,
          a || {}
        );
        const { [sortVia]: timeB = null } = get(
          b,
          nestedPath,
          b || {}
        );

        console.log(timeA, timeB);

        return this.lastSorted === "desc"
          ? new Date(timeA) - new Date(timeB)
          : new Date(timeB) - new Date(timeA);
      });
      this.lastSorted = this.lastSorted === "desc" ? "asc" : "desc";
      this.activeSort = index;
    },

    sortByBool(sortVia, _, index) {
      this.users = this.users.sort((a, b) => {
        if (a[sortVia] !== b[sortVia]) return a[sortVia] ? -1 : 1;
        else return a[sortVia] > b[sortVia] ? 1 : -1;
      });
      this.lastSorted = this.lastSorted === "desc" ? "asc" : "desc";
      this.activeSort = index;
    },

    sortByAlphabate(sortVia, _, index) {
      this.users = this.users.sort((a, b) => {
        let A =
          typeof a[sortVia] === "string" && a[sortVia].toUpperCase();
        let B =
          typeof b[sortVia] === "string" && b[sortVia].toUpperCase();
        if (sortVia === "role") {
          A = this.roles.find(
            ({ id }) => id === A.toLowerCase()
          ).name;
          B = this.roles.find(
            ({ id }) => id === B.toLowerCase()
          ).name;
        }

        if (sortVia === "status") {
          A = !a.isVerified
            ? "Invited"
            : a.status
            ? "Active"
            : "Not Active";
          B = !b.isVerified
            ? "Invited"
            : b.status
            ? "Active"
            : "Not Active";
        }

        if (sortVia === "countryCode") {
          A =
            this.countries[a.countryCode] ||
            this.countriesPrimary[a.countryCode];
          B =
            this.countries[b.countryCode] ||
            this.countriesPrimary[b.countryCode];
        }

        if (!A) return 1;
        if (!B) return -1;

        if (this.lastSorted === "desc") return A.localeCompare(B);
        else return B.localeCompare(A);
      });
      this.lastSorted = this.lastSorted === "desc" ? "asc" : "desc";
      this.activeSort = index;
    },
    async fetchUserByRepresentative() {
      this.isLoaded = false;

      const { error_code, success_code } = this.$route.query;

      if (error_code) {
        this.error = true;
        this.errorCode = error_code;
      }

      if (success_code) {
        this.success = true;
        this.successCode = success_code;
      }

      try {
        const representativeId = this.$route.params.representativeId;
        this.users = await this[GET_REPRESENTATIVE_USERS](
          representativeId
        );
        // this.companies = this[GET_COMPANIES_BY_USER_ID](this.user._id);
      } catch (error) {
        this.isLoaded = true;
        this.mainError = true;
      }
      this.isLoaded = true;
    },

    // getCompanyNameById(companyId) {
    //   let company = this.companies.find(company => {
    //     return company._id === companyId;
    //   });

    //   if (company === undefined) {
    //     return 'No Company';
    //   }

    //   return company.name;
    // },

    // getDate(timestamp) {
    //   let date = new Date(timestamp);
    //   var monthNames = [
    //     this.$t('Jan.'),
    //     this.$t('Feb.'),
    //     this.$t('Mar.'),
    //     this.$t('Apr.'),
    //     this.$t('May'),
    //     this.$t('Jun.'),
    //     this.$t('Jul.'),
    //     this.$t('Aug.'),
    //     this.$t('Sep.'),
    //     this.$t('Oct.'),
    //     this.$t('Nov.'),
    //     this.$t('Dec.'),
    //   ];
    //   var day = date.getDate();
    //   var monthIndex = date.getMonth();
    //   var year = date.getFullYear();
    //   return day + ' ' + monthNames[monthIndex] + ' ' + year;
    // },
  },

  watch: {
    locale: {
      handler(lang) {
        this.$nextTick(() => {
          this.contentWidth = this.$refs.tableHeader.clientWidth;
        });
      },
      deep: true,
    },
  },

  async created() {
    const representativeId = this.$route.params.representativeId;
    if (representativeId) {
      await this.fetchUserByRepresentative();
    } else {
      this.users = [...this.allUsers];
    }
  },
};
</script>

<style lang="scss" scoped>
.top-scroll,
.content {
  overflow-x: scroll;
  max-width: 100%;
  max-height: 850px;
  overflow: scroll;
}
.top-scroll {
  margin-top: 50px;
}
.toggleSortIcon {
  visibility: visible !important;
}
</style>
