<template>
  <div class="container main-container main-container--hygiene view">
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">{{ 'Tell Us About Your Transition Area and Procedures' | translate }}</h2>
        <modal-info :value="'tell-us'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">{{ 'Transition' | translate }}</h2>
            <p class="px-3">
              {{ 'For more information about Transition Areas, click the link to learn more from' | translate }}
              <a
                :href="$t('https://library.zinprofirststep.com/transition-heifers/')"
                @click="$goToLameness($t('/transition-heifers/'), $event)"
                target="_blank"
                >{{ 'FirstStep Resources' | translate }}</a
              >.
            </p>
            <div class="row modal-bottom pb-3">
              <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                'Cancel' | translate
              }}</a>
            </div>
          </template>
        </modal-info>
      </div>
    </div>
    <hr class="mb-lg-4" />
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 mb-3 mb-sm-2">{{ 'Herd Information' | translate }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="mb-1">{{ 'Number of Cows in Herd' | translate }}</label>
          <currency-input
            v-model="evaluation.data.herdSize"
            :class="'form-control'"
            disabled
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: 0,
            }"
          />
        </div>
      </div>
      <div class="col-md-12">
        <label class="mb-1">{{ 'Operation Type' | translate }}</label>
        <select v-model="evaluation.data.resting_space" class="form-control">
          <option value="null" disabled>
            {{ 'Select' | translate }}
          </option>
          <option v-for="operationType in operationTypes" :key="operationType.value" :value="operationType.value">
            {{ operationType.label | translate }}
          </option>
        </select>
      </div>
    </div>
    <hr class="mt-2 mb-4 mt-md-3" />
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 mb-3 mb-sm-2">{{ 'Transition Performance Parameters' | translate }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label class="mb-1"
          >{{ 'Projected First Test 305-Day Milk' | translate }} ({{
            $t($getEquivalentUnit(evaluation.data.measurements, 'kg'))
          }})</label
        >
        <modal-info :value="'projected-first-test'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ 'Projected First Test 305-Day Milk' | translate }} ({{
                $t($getEquivalentUnit(evaluation.data.measurements, 'kg'))
              }})
            </h2>
            <p class="px-3 mb-2">
              {{ '[DC305 Command]:(Record the Y axis intercept)' | translate }} GRAPH FSTPJ BY DIM LCTGP FOR
              DIM&lt;366\\R; GRAPH 1STPJ BY DIM LCTGP FOR DIM&lt;366 FDAT>-366\RBL
            </p>
            <div class="row modal-bottom pb-3">
              <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{'Cancel' | translate }}</a>
            </div>
          </template>
        </modal-info>
        <currency-input
          v-model.number="computedProjectedFirstTest305"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }"
        />
      </div>
      <div class="col-md-12">
        <label class="mb-1">{{ 'Peak Ratio' | translate }} %</label>
        <modal-info :value="'peak-ratio'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">{{ 'Peak Ratio' | translate }} %</h2>
            <p class="px-3 mb-2">
              {{ '1st lactation peaks as a percent of ≥2 lactation peaks [DC305 Command]:' | translate }} SUM PEAKM FOR
              LACT=1 FDAT>-366\L;SUM PEAKM FOR LACT>1 FDAT>-366\L
            </p>
            <div class="row modal-bottom pb-3">
              <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                'Cancel' | translate
              }}</a>
            </div>
          </template>
        </modal-info>
        <currency-input
          v-model.number="evaluation.data.peak_ratio"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            
          }"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label class="mb-1">{{ '% First Test Fat-Protein >1.4, 1st Lactation' | translate }}</label>
        <modal-info :value="'first-test'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ '% First Test Fat-Protein >1.4, 1st Lactation' | translate }}
            </h2>
            <p class="px-3 mb-2">
              {{ '% of 1st lactation cows with first test fat-protein ratio >1.4 [DC305 Command]:' | translate }} SUM
              FPR=140 LACT=1 FOR FTDIM=5-40 FDAT>-366\L
            </p>
            <div class="row modal-bottom pb-3">
              <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                'Cancel' | translate
              }}</a>
            </div>
          </template>
        </modal-info>
        <currency-input
          v-model.number="evaluation.data.first_test_fat_protein_ratio_1"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }"
        />
      </div>
      <div class="col-md-12">
        <label class="mb-1">{{ '% First Test Fat-Protein >1.4, ≥ 2nd Lactation' | translate }}</label>
        <modal-info :value="'first-test-2'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ '% First Test Fat-Protein >1.4, ≥ 2nd Lactation' | translate }}
            </h2>
            <p class="px-3 mb-2">
              {{ '% of ≥2 lactation cows with first test fat-protein ratio >1.4 [DC305 Command]:' | translate }} SUM
              FPR=140 LACT>1 FOR FTDIM=5-40 FDAT>-366\L
            </p>
            <div class="row modal-bottom pb-3">
              <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                'Cancel' | translate
              }}</a>
            </div>
          </template>
        </modal-info>
        <currency-input
          v-model.number="evaluation.data.first_test_fat_protein_ratio_2"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-24 col-lg-12">
        <label class="mb-1">{{
          '% of 1st Lactation Cows with First Test (5 to 40 DIM) SCC ≥ 200,000' | translate
        }}</label>
        <modal-info :value="'scc-1'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ '% of 1st Lactation Cows with First Test (5 to 40 DIM) SCC ≥ 200,000' | translate }}
            </h2>
            <p class="px-3 mb-2">
              {{ '[DC305 Command]' | translate }}: PLOT LOG1=4 FOR TDD1>-366 TDY1=4-45 LOG1>0 BY LOG1 \RD366 FOR LACT=1
            </p>
            <div class="row modal-bottom pb-3">
              <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                'Cancel' | translate
              }}</a>
            </div>
          </template>
        </modal-info>
        <currency-input
          v-model.number="evaluation.data.scc_at_first_test_1"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            
          }"
        />
      </div>
      <div class="col-md-24 col-lg-12">
        <label class="mb-1">{{
          '% of ≥ 2 Lactation Cows with First Test (5 to 40 DIM) SCC ≥ 200,000' | translate
        }}</label>
        <modal-info :value="'scc-2'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ '% of ≥ 2 Lactation Cows with First Test (5 to 40 DIM) SCC ≥ 200,000' | translate }}
            </h2>
            <p class="px-3 mb-2">
              {{ '[DC305 Command]' | translate }}: PLOT LOG1=4 FOR TDD1>-366 TDY1=4-45 LOG1>0 BY LOG1 \RD366 FOR LACT>1
            </p>
            <div class="row modal-bottom pb-3">
              <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                'Cancel' | translate
              }}</a>
            </div>
          </template>
        </modal-info>
        <currency-input
          v-model.number="evaluation.data.scc_at_first_test_2"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label class="mb-1">{{ '% Stillbirths, 1st Lactation' | translate }}</label>
        <modal-info :value="'stillbirths-1'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ '% Stillbirths, 1st Lactation' | translate }}
            </h2>
            <p class="px-3 mb-2">{{ '[DC305 Command]' | translate }}: EVENTS FOR LACT=1\3</p>
            <div class="row modal-bottom pb-3">
              <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                'Cancel' | translate
              }}</a>
            </div>
          </template>
        </modal-info>
        <currency-input
          v-model.number="evaluation.data.stillbirths_1"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }"
        />
      </div>
      <div class="col-md-12">
        <label class="mb-1">{{ '% Stillbirths, ≥ 2nd Lactation' | translate }}</label>
        <modal-info :value="'stillbirths-2'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ '% Stillbirths, ≥ 2nd Lactation' | translate }}
            </h2>
            <p class="px-3 mb-2">{{ '[DC305 Command]' | translate }}: EVENTS FOR LACT>1\3</p>
            <div class="row modal-bottom pb-3">
              <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                'Cancel' | translate
              }}</a>
            </div>
          </template>
        </modal-info>
        <currency-input
          v-model.number="evaluation.data.stillbirths_2"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label class="mb-1">{{ 'Dry Period "Cure" Rate' | translate }}</label>
        <modal-info :value="'dry-period'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ 'Dry Period "Cure" Rate' | translate }}
            </h2>
            <p class="px-3 mb-2">
              {{ '% Cows ≥200,000 at Dry Off, <200,000 at 1st Test; [DC305 Command]:' | translate }} SUM LOG1=4
              DRYLG=4 FOR FDAT>-366 FDAT>DDAT LOG1>0 DRYLG>0 FTDIM=5-40\L
            </p>
            <div class="row modal-bottom pb-3">
              <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                'Cancel' | translate
              }}</a>
            </div>
          </template>
        </modal-info>
        <currency-input
          v-model.number="evaluation.data.dry_period_cure_rate"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }"
        />
      </div>
      <div class="col-md-12">
        <label class="mb-1">{{ 'Turnover % (Sold + Died) ≤ 60 DIM' | translate }}</label>
        <modal-info :value="'turnover'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ 'Turnover % (Sold + Died) ≤ 60 DIM' | translate }}
            </h2>
            <p class="px-3 mb-2">
              {{ '[DC305 Command]' | translate }}: EVENTS FOR LACT>0 FDAT=-425--60\\6S425I011415 (FRESH, SOLD, DIED)
            </p>
            <div class="row modal-bottom pb-3">
              <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                'Cancel' | translate
              }}</a>
            </div>
          </template>
        </modal-info>
        <currency-input
          v-model.number="evaluation.data.turnover_sold_died"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label class="mb-1">{{ '% Death Loss, ≤ 60 DIM' | translate }}</label>
        <modal-info :value="'deaths-60'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 h2--line-sm text-center pb-2 pt-3 px-3">
              {{ '% Death Loss, ≤ 60 DIM' | translate }}
            </h2>
            <p class="px-3 mb-2">
              {{ '[DC305 Command]' | translate }}: EVENTS FOR LACT>0 FDAT=-425--60\6S425I0115 (FRESH, DIED)
            </p>
            <div class="row modal-bottom pb-3">
              <a href="#" class="btn-cancel-modal d-flex justify-content-center" data-dismiss="modal">{{
                'Cancel' | translate
              }}</a>
            </div>
          </template>
        </modal-info>
        <currency-input
          v-model.number="evaluation.data.deaths"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }"
        />
      </div>
    </div>

    <hr class="mb-lg-4" />
    <div class="row">
      <div class="card-body__top col-lg-24">
        <h2 class="h2 mb-3 mb-sm-2">{{ 'Facilities & Management' | translate }}</h2>
        <p>* {{ 'Field Required' | translate }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="form-group">
          <label class="mb-1">{{ 'Number of Cows in Herd' | translate }}</label>
          <currency-input
            v-model="evaluation.data.herdSize"
            :class="'form-control'"
            disabled
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
            }"
          />
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <!-- b4dPotato not translated yet (WHOLE BLOCK) -->
        <label class="mb-1">{{ 'Calving Pattern' | translate }}*</label>
        <select v-model="evaluation.data.calvin_pattern" class="form-control" @change="setCalvingLength">
          <option :value="undefined" hidden disabled>{{ 'Select' | translate }}</option>
          <option :value="comparativeValues.calvinPattern.yearRound"
            >{{ 'Year-round' | translate }}{{ comparativeValues.yearRound }}</option
          >
          <option :value="comparativeValues.calvinPattern.seasonal">{{ 'Seasonal' | translate }}</option>
        </select>
      </div>
      <div class="col-lg-8 col-md-12">
        <!-- b4dPotato not translated yet (WHOLE BLOCK) -->
        <label class="mb-1">{{ 'Length of Calving Period (Days)' | translate }}*</label>
        <currency-input
          v-model="evaluation.data.length_in_calving_pen"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }"
        />
      </div>
      <div class="col-lg-8 col-md-12">
        <label class="mb-1">{{ 'Length of Dry Period (Days)' | translate }}*</label>
        <currency-input
          v-model="evaluation.data.length_of_dry_period_days"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            
          }"
        />
      </div>
      <div class="col-lg-8 col-md-12">
        <label class="mb-1">{{ 'Actual Days in Close-Up Pen' | translate }}*</label>
        <currency-input
          v-model="evaluation.data.close_up_days"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }"
        />
      </div>
      <div class="col-lg-8 col-md-12">
        <!-- b4dPotato not translated yet (WHOLE BLOCK) -->
        <label class="mb-1">{{ 'Actual Days in Maternity Pen' | translate }}</label>
        <currency-input
          v-model="evaluation.data.maternity_days"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: 0
          }"
        />
      </div>
      <div class="col-lg-8 col-md-12">
        <label class="mb-1">{{ 'Actual Days in Fresh Pen' | translate }}*</label>
        <currency-input
          v-model="evaluation.data.fresh_days"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }"
        />
      </div>
      <div class="col-lg-8 col-md-12">
        <label class="mb-1">{{ 'Are Close-Up Heifers Mixed with Cows?' | translate }}</label>
        <select v-model="evaluation.data.close_up_heifers_mixed_with_cows" class="form-control">
          <option :value="undefined" hidden disabled>{{ 'Select' | translate }}</option>
          <option value="yes">{{ 'Yes' | translate }}</option>
          <option value="no">{{ 'No' | translate }}</option>
        </select>
      </div>
      <div class="col-lg-8 col-md-12">
        <label class="mb-1">{{ 'Frequency Animals are Moved to Close-Up Pen' | translate }}</label>
        <select v-model="evaluation.data.how_many_moves_into_close_up_pen" class="form-control">
          <option :value="undefined" hidden disabled>
            {{ 'Select' | translate }}
          </option>
          <option v-for="item in howManyMovesIntoCloseUpPen" :key="item.value" :value="item.value">
            {{ item.label | translate }}
          </option>
        </select>
      </div>
      <div class="row col-lg-24 align-items-end mx-0 px-0">
        <div class="col-lg-8 col-md-12">
          <label class="mb-1">{{
            'When are Cows Moved into Calving Pen (If Separate from Close-Up)?' | translate
          }}</label>
          <select v-model="evaluation.data.move_into_calving_pen" class="form-control">
            <option :value="undefined" hidden disabled>{{ 'Select' | translate }}</option>
            <option value="3_7">{{ '3-7 days before due date' | translate }}</option>
            <option value="2_6">{{ '< 2 days or > 7 days before due date' | translate }}</option>
          </select>
        </div>
        <div class="col-lg-8 col-md-12">
          <label class="mb-1">{{ 'How are Sick Cows Managed?' | translate }}</label>
          <select v-model="evaluation.data.how_are_sick_cows_managed" class="form-control">
            <option :value="undefined" hidden disabled>{{ 'Select' | translate }}</option>
            <option value="mixed_all_times">{{ 'Mixed at all times' | translate }}</option>
            <option value="segregated">{{ 'Segregated at milking' | translate }}</option>
            <option value="segregated_all_times">{{ 'Segregated at all times' | translate }}</option>
          </select>
        </div>
        <div class="col-lg-8 col-md-12">
          <!-- b4dPotato not translated yet (WHOLE BLOCK) -->
          <label class="mb-1">{{ 'Dry Off Protocol' | translate }}</label>
          <select v-model="evaluation.data.dry_off_protocol" class="form-control">
            <option :value="undefined" hidden disabled>{{ 'Select' | translate }}</option>
            <option value="daily">{{ 'Daily' | translate }}</option>
            <option value="multiple_per_week">{{ 'Multiple Times per Week' | translate }}</option>
            <option value="weekly">{{ 'Weekly' | translate }}</option>
          </select>
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <label class="mb-1">{{
          ('Yield (305 days, ' + $t($getEquivalentUnit(evaluation.data.measurements, 'kg')) + ')') | translate
        }}</label>
        <currency-input
          v-model="evaluation.data.yield_305_days"
          :class="'form-control'"
          :options="{
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: {min: 0, max :2},
          }"
        />
      </div>
    </div>
    <div class="row misc-bottom mt-4 justify-content-end">
      <div class="col-md-12">
        <router-link
          :class="{ disabled: emptyRequiredfields.length > 0 }"
          :to="
            '/users/' +
              $route.params.userId +
              '/evaluations/' +
              $route.params.evaluationId +
              '/groups/' +
              $route.params.groupId +
              '/assessors/' +
              $route.params.assessorType +
              '/main'
          "
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ 'Next' | translate }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import PresentationNotes from './assessor_components/presentation-notes';
import PersonalNotes from './assessor_components/personal-notes';
import Question from './assessor_components/question';
import ModalInfo from '@/components/misc/modal-info.vue';
import ModalWarning from '@/components/misc/modal-warning';
import PhotoUpload from '@/components/misc/photoUpload';
import HygieneHeader from './assessor_components/hygiene-header.vue';
import transitionConstants from '../../libs/Classes/constants/transition';
import TimeBudget from '@/libs/Classes/TimeBudget.js';
import Evaluation from '@/libs/Classes/Evaluation.js';
import Group from '@/libs/Classes/Group.js';
import convert from 'convert-units';
import { comparativeValues } from '@/libs/Classes/constants/transition';
import requestReportMixin from '@/mixins/requestReport.js';
import { SET_UPDATE_EVALUATION } from '../../store/types';
import CurrencyInput from "@/components/common/CurrencyInput.vue";
const requiredFields = [
  'calvin_pattern',
  'length_in_calving_pen',
  'length_of_dry_period_days',
  'close_up_days',
  'fresh_days',
];
const fieldsToAddInEvelData = [
  'calvin_pattern',
  'length_in_calving_pen',
  'length_of_dry_period_days',
  'close_up_days',
  'fresh_days',
  'yield_305_days'
];

const howManyMovesIntoCloseUpPen = [
  {
    value: 'multiple',
    label: 'Multiple times per week',
  },
  {
    value: 'weekly',
    label: 'Weekly filling of group pen',
  },
  {
    value: 'more_than_14',
    label: 'Cows remain with same group of cows >14 days',
  },
];

const operationTypes = [
  {
    value: 'freestall',
    label: 'Freestall',
  },
  {
    value: 'loose_bedding',
    label: 'Loose bedding',
  },
];

export default {
  components: {
    PersonalNotes,
    PresentationNotes,
    Question,
    HygieneHeader,
    PhotoUpload,
    ModalInfo,
    ModalWarning,
    CurrencyInput,
  },

  props: ['assessor', 'group', 'evaluation'],
  mixins: [requestReportMixin],
  computed: {
    computedProjectedFirstTest305: {
      cache: false,
      get() {
        return this.evaluation.data[
          'projected_first_test_305_' + this.$getEquivalentUnit(this.evaluation.data.measurements, 'kg')
        ];
      },
      set(value) {
        if (this.evaluation.data.measurements === 'metric') {
          this.evaluation.data['projected_first_test_305_kg'] = value;
          this.evaluation.data['projected_first_test_305_lb'] = parseInt(
            convert(value)
              .from('kg')
              .to('lb')
          ).toDecimal(0);
        } else {
          this.evaluation.data['projected_first_test_305_lb'] = value;
          this.evaluation.data['projected_first_test_305_kg'] = parseInt(
            convert(value)
              .from('lb')
              .to('kg')
          ).toDecimal(0);
        }
      },
    },

    emptyRequiredfields() {
      const emptyFields = [];

      requiredFields.forEach(field => {
        if (!this.evaluation.data[field] && String(this.evaluation.data[field]) !== '0') emptyFields.push(field);
      });
      console.log({ emptyFields });
      return emptyFields;
    },
  },

  data() {
    return {
      currentPage: 'setup',
      isLoaded: false,
      minSoftBedding: parseInt(
        convert(20)
          .from('cm')
          .to(this.$getEquivalentUnit(this.evaluation.data.measurements, 'cm'))
          .toFixed(0)
      ),
      transitionConstants,
      comparativeValues,

      howManyMovesIntoCloseUpPen,
      operationTypes: [
        {
          value: 'freestall',
          label: 'Freestall',
        },
        {
          value: 'loose_bedding',
          label: 'Loose bedding',
        },
      ],
    };
  },

  created() {
    // let evaluationClass = new Evaluation(this, this.evaluation);
    // let groupClass = new Group(this, evaluationClass, this.group);
    this.addRequiredFieldsInEvaluation();
  },

  methods: {
    addRequiredFieldsInEvaluation() {
        const evaluation = {...this.evaluation.data};
      fieldsToAddInEvelData.forEach(field => {
        if(!Object.hasOwnProperty.call(evaluation.data, field)){
          evaluation.data[field] = null  
        };
      });
      this.$store.commit(`evaluations/${SET_UPDATE_EVALUATION}`, evaluation)
    },
    setCalvingLength(e) {
      if (e.target.value === comparativeValues.calvinPattern.yearRound) {
        this.evaluation.data.length_in_calving_pen = 365;
      } else {
        this.evaluation.data.length_in_calving_pen = '';
      }
    },

    updateValue(field) {
      let name = null;
      let value = null;
      if (field.target) {
        name = field.target.dataset.name + '_' + this.$getEquivalentUnit(this.evaluation.data.measurements, 'kg');
        value = field.target.value;
        this.evaluation.data[name] = value;
      }
      console.log({ name, value });
      //this.evaluation.data[]
    },

    // requestReport(evaluationName) {
    //     this.$store.dispatch('requestReport', {
    //         currentVueInstance: this,
    //         evaluationName: evaluationName
    //     })
    // },

    calculateSliderFillLeft(value) {
      let percent = 100 - value * 2;
      if (percent < 0) {
        percent = 0;
      }
      return percent > 50 ? `left:calc(${percent}% - 14px)` : `left:${percent}%`;
    },

    resetDefaults() {
      this.evaluation.data = {
        cows_only_standing: 5,
        cows_standing_or_lying_in_stall: 95,
        milked_per_hour: 64,
        milkings_per_day: 2,
        other_bedding_type: null,
        production_type: 'confined_cattle',
        quantity_of_grain_supplements: null,
        stalls_available_in_pen: 100,
        personal_notes: null,
        presentation_notes: null,
        time_held_from_paddocks: null,
        type_production: null,
        walking_time_per_day: null,
      };
    },
  },
};
</script>
