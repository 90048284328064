<template>
  <div class="container main-container main-container--hygiene view">
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">
          {{ 'Tell Us About Your Holding Area' | translate }}
        </h2>
        <modal-info :value="'tell-us'" class="d-inline">
          <template slot="modal-content">
            <h2 class="h2 pb-2 pt-3 px-3 text-center">
              {{ 'Holding Area' | translate }}
            </h2>
            <p class="px-3">
              {{
                'For more information about Holding Areas, click the link to learn more from'
                  | translate
              }}
              <a
                :href="
                  $t(
                    'https://library.zinprofirststep.com/holding-areas-freestalls/'
                  )
                "
                @click="$goToLameness($t('/holding-areas-freestalls/'), $event)"
                target="_blank"
                >{{ 'FirstStep Resources' | translate }}</a
              >.
            </p>
            <div class="row modal-bottom pb-3">
              <a
                href="#"
                class="btn-cancel-modal d-flex justify-content-center"
                data-dismiss="modal"
                >{{ 'Cancel' | translate }}</a
              >
            </div>
          </template>
        </modal-info>
      </div>
    </div>
    <hr class="mb-lg-4" />
    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">{{ $t('General') }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <label class="mb-1">{{
          $t('Holding Area Size') +
            ' (' +
            $t(
              $t($getEquivalentUnit(evaluation.data.measurements, 'square meters'))
            ) +
            ')'
        }}</label>
        <currency-input                 
            v-model="converted_holding_area_size"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
            }"
          />
      </div>
    </div>

    <hr />

    <question-three
      @change="
        assessor.data.do_cows_spend_more_than_3_hours_day_on_average_in_the_holding_area =
          $event.value
      "
      :initial-value="
        assessor.data
          .do_cows_spend_more_than_3_hours_day_on_average_in_the_holding_area
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Do cows spend more than 3 hours/day, on average, in the holding area?'
            )
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.are_first_lactation_heifers_mixed_with_mature_cows_in_the_holding_area =
          $event.value
      "
      :initial-value="
        assessor.data
          .are_first_lactation_heifers_mixed_with_mature_cows_in_the_holding_area
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Are first lactation heifers mixed with mature cows in the holding area?'
            )
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_crowd_gate_used_appropriately = $event.value
      "
      :initial-value="assessor.data.is_the_crowd_gate_used_appropriately"
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Is the crowd gate used appropriately (used to fill up space behind cows, not used to push cows)?'
            )
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_a_large_proportion_more_than_30_of_cows_with_their_heads_up_rather_than_down_looking_at_the_ground =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_a_large_proportion_more_than_30_of_cows_with_their_heads_up_rather_than_down_looking_at_the_ground
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Is a large proportion (more than 30%) of cows with their heads up, rather than down looking at the ground?'
            )
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_the_holding_area_floor_excessively_sloped =
          $event.value
      "
      :initial-value="
        assessor.data.is_the_holding_area_floor_excessively_sloped
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Is the holding area floor excessively sloped (greater than 2%)?'
            )
          }}
        </label>
      </div>
    </question-three>

    <hr />

    <question-three
      @change="
        assessor.data.is_there_any_slipping_as_the_cows_move_between_each_other_that_may_traumatize_the_hoof =
          $event.value
      "
      :initial-value="
        assessor.data
          .is_there_any_slipping_as_the_cows_move_between_each_other_that_may_traumatize_the_hoof
      "
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{
            $t(
              'Is there any slipping as the cows move between each other that may traumatize the hoof?'
            )
          }}
        </label>
      </div>
    </question-three>

    <hr class="mb-lg-4" />

    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">{{ 'Fans' | translate }}</h2>
      </div>
    </div>

    <hr />

    <question-three
      @change="
        assessor.data.are_recirculating_fans_in_the_holding_area = $event.value
      "
      :initial-value="assessor.data.are_recirculating_fans_in_the_holding_area"
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{ $t('Are recirculating fans in the holding area?') }}
        </label>
      </div>
    </question-three>

    <hr />

    <div class="row">
      <div
        class="col-lg-12"
        v-show="assessor.data.are_recirculating_fans_in_the_holding_area === 1"
      >
        <label class="mb-1">{{
          $t('Temperature that the fans activate (°') +
            $t($getEquivalentUnit(evaluation.data.measurements, 'C')) +
            ')'
        }}</label>
        <currency-input                 
            v-model="converted_temperature_that_the_fans_activate"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
              
            }"
          />
      </div>

      <div class="col-lg-12">
        <label class="mb-1">{{ $t('Group/Pen Size in Holding Area') }}</label>
        <currency-input                 
            v-model="assessor.data.group_pen_size_in_holding_area"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: 0,
            }"
          />
      </div>
    
      <template v-if="assessor.data.are_recirculating_fans_in_the_holding_area === 1" >
      <Fans 
         :fan-width.sync="converted_fan_width_1"
         no-of-fan="number_of_fans_1" 
         :assessor="assessor"
         :evaluation="evaluation"
       />
       <Fans 
        :fan-width.sync="converted_fan_width_2"
         no-of-fan="number_of_fans_2" 
         :assessor="assessor"
         :evaluation="evaluation"
       />
       <Fans 
        :fan-width.sync="converted_fan_width_3"
         no-of-fan="number_of_fans_3" 
         :assessor="assessor"
         :evaluation="evaluation"
       />
       <div
        class="col-lg-12"
      >
        <div class="form-group">
          <label class="mb-1"
            >{{
              `${$t('Total air movement capacity')} (${$t($getEquivalentUnit(evaluation.data.measurements, 'cmh'))}${$t('/cow')})` 
            }}</label
          >
          <currency-input                 
            v-model="computed_air_movement_capacity"
            readonly
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: 0,
            }"
          />
        </div>
      </div>
      </template>

      </div>

    <hr class="mb-lg-4" />

    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">{{ 'Soakers/Misters' | translate }}</h2>
      </div>
    </div>

    <hr />

    <question-three
      @change="
        assessor.data.are_soakers_misters_in_the_holding_area = $event.value
      "
      :initial-value="assessor.data.are_soakers_misters_in_the_holding_area"
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{ $t('Are soakers/misters in the holding area?') }}
        </label>
      </div>
    </question-three>

    <hr v-show="assessor.data.are_soakers_misters_in_the_holding_area === 1" />

    <div class="row">
      <div
        class="col-lg-8"
        v-show="assessor.data.are_soakers_misters_in_the_holding_area === 1"
      >
        <label class="mb-1">{{
          $t('Temperature that the soakers activate (°') +
            $t($getEquivalentUnit(evaluation.data.measurements, 'C')) +
            ')'
        }}</label>
        <currency-input                 
            v-model="converted_temperature_that_the_soakers_activate"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
            }"
          />
      </div>
      <div
        class="col-lg-8"
        v-show="assessor.data.are_soakers_misters_in_the_holding_area === 1"
      >
        <label class="mb-1">{{ $t('Duration (minutes)?') }}</label>
        <currency-input                 
            v-model="assessor.data.soakers_duration"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
            }"
          />
      </div>
      <div
        class="col-lg-8"
        v-show="assessor.data.are_soakers_misters_in_the_holding_area === 1"
      >
        <label class="mb-1">{{ $t('Frequency (minutes)?') }}</label>
        <currency-input                 
            v-model="assessor.data.soakers_frequency"
            :options="{
              currency: 'USD',
              currencyDisplay: 'hidden',
              precision: {min: 0, max :2},
            }"
          />
      </div>
    </div>

    <hr class="mb-lg-4" />

    <div class="row">
      <div class="col-lg-24">
        <h2 class="h2 d-inline-block">{{ 'Shade' | translate }}</h2>
      </div>
    </div>

    <hr />

    <question-three
      @change="
        assessor.data.is_there_shade_over_the_holding_area = $event.value
      "
      :initial-value="assessor.data.is_there_shade_over_the_holding_area"
    >
      <div class="mb-1">
        <label class="label d-inline">
          {{ $t('Is there shade over the holding area?') }}
        </label>
      </div>
    </question-three>

    <div class="row">
      <div class="col-lg-24">
        <hr />
      </div>
    </div>
    <div class="row my-0">
      <div class="col-md-12">
        <personal-notes v-model="assessor.data.personal_notes"></personal-notes>
      </div>
      <div class="col-md-12">
        <presentation-notes
          v-model="assessor.data.presentation_notes"
        ></presentation-notes>
      </div>
    </div>
    <div
      class="row mx-0 mt-4 justify-content-center justify-content-md-start center align-items-center"
    >
      <photoUpload :assessorId="11" :assessor="assessor"></photoUpload>
    </div>

    <hr class="mb-3 mb-md-5 mt-3 mt-md-5" />

    <div class="row misc-bottom">
      <div class="col-md-24">
        <router-link
          v-if="user && user.role !== 'data_entry'"
          :to="'/reports'"
          class="btn btn-primary btn-full--sm pull-right"
          >{{ 'Reports' | translate }}</router-link
        >
        <button
          v-else-if="
            user && user.role === 'data_entry' && !$store.state.offline
          "
          data-toggle="modal"
          @click="requestReport(evaluation.name)"
          :data-target="['#requestSent-' + user.id]"
          class="btn btn-primary btn-full--sm pull-right"
        >
          {{ 'Request Report' | translate }}
          <span v-show="requestReportLoader" class="loader ml-1"></span>
        </button>
      </div>
    </div>
    <div
      v-show="successMessage"
      class="alert alert-primary mt-3 mb-0"
      role="alert"
    >
      {{ 'Your request has been sent!' | translate }}
    </div>

    <div
      v-show="errorMessage"
      class="alert alert-danger mt-3 mb-0"
      role="alert"
    >
      {{ 'An error has occurred during your request!' | translate }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import PresentationNotes from './assessor_components/presentation-notes';
import PersonalNotes from './assessor_components/personal-notes';
import Question from './assessor_components/question';
import QuestionThree from './assessor_components/question-three';
import ModalInfo from '@/components/misc/modal-info.vue';
import PhotoUpload from '@/components/misc/photoUpload';
import HygieneHeader from './assessor_components/hygiene-header.vue';
import HoldingArea from '@/libs/Classes/HoldingArea.js';
import Evaluation from '@/libs/Classes/Evaluation.js';
import Group from '@/libs/Classes/Group.js';
import convert from 'convert-units';
import requestReportMixin from '@/mixins/requestReport.js';
import Fans from '@/components/misc/Fans.vue';
import CurrencyInput from "@/components/common/CurrencyInput.vue";

export default {
  components: {
    PersonalNotes,
    PresentationNotes,
    Question,
    QuestionThree,
    HygieneHeader,
    PhotoUpload,
    ModalInfo,
    Fans,
    CurrencyInput
},
  mixins: [requestReportMixin],
  props: ['assessor', 'group', 'evaluation'],
  data() {
    return {
      holdingArea: null,
    };
  },
  computed: {
    fanWidths() {
      const widths_in_cm = [
        35.56,
        40.64,
        45.72,
        60.96,
        91.44,
        121.92,
        127,
        129.54,
        137.16,
      ];
      let converted_widths = [];

      for (let i = 0; i < widths_in_cm.length; i++) {
        let converted_value = parseInt(
          convert(widths_in_cm[i])
            .from('cm')
            .to(
              this.$getEquivalentUnit(this.evaluation.data.measurements, 'cm')
            )
            .toFixed(0)
        );

        converted_widths.push({
          value: widths_in_cm[i],
          label: converted_value,
        });
      }

      return converted_widths;
    },
    converted_holding_area_size: {
      get() {
        return this.holdingArea.getHoldingAreaSize(false);
      },
      set(newValue) {
        return this.holdingArea.setHoldingAreaSize(newValue);
      },
    },
    converted_temperature_that_the_soakers_activate: {
      get() {
        return this.holdingArea.getSoakersTemperatureThatTheSoakersActivate(
          false
        );
      },
      set(newValue) {
        return this.holdingArea.setSoakersTemperatureThatTheSoakersActivate(
          newValue
        );
      },
    },
    converted_temperature_that_the_fans_activate: {
      get() {
        return this.holdingArea.getTemperatureThatTheFansActivate(false);
      },
      set(newValue) {
        return this.holdingArea.setTemperatureThatTheFansActivate(newValue);
      },
    },
    computed_air_movement_capacity() {
      return this.holdingArea.getCurrentCFM();
    },
    converted_fan_width_1: {
      get() {
       return this.holdingArea.getFanWidth('fan_width_1');
      },
      set(newValue) {
        return this.holdingArea.setFanWidth(newValue, 'fan_width_1');
      },
    },
    converted_fan_width_2: {
      get() {
        return this.holdingArea.getFanWidth('fan_width_2');
      },
      set(newValue) {
       return this.holdingArea.setFanWidth(newValue, 'fan_width_2');
      },
    },
    converted_fan_width_3: {
      get() {
        return this.holdingArea.getFanWidth('fan_width_3');
      },
      set(newValue) {
       return this.holdingArea.setFanWidth(newValue, 'fan_width_3');
      },
    },
  },
  methods: {
    // requestReport(evaluationName) {
    //   this.$store.dispatch('requestReport', {
    //       currentVueInstance: this,
    //       evaluationName: evaluationName
    //   })
    // },
  },
  created() {
    let evaluationClass = new Evaluation(this, this.evaluation);
    let groupClass = new Group(this, evaluationClass, this.group);
    this.holdingArea = new HoldingArea(
      this,
      evaluationClass,
      groupClass,
      this.assessor
    );
  },
};
</script>
