<template>
    <input
      ref="inputRef"
      type="text"
      class="form-control"
    />
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'
import { watch } from 'vue'
import { languages } from '../../main'

export default {
  name: 'CurrencyInput',
  props: {
    value: Number,
    options: Object,
  },
  setup(props) {
    const { inputRef, setOptions, setValue } = useCurrencyInput(props.options)

    watch(
      () => props.value,
      (value) => {
        setValue(value);
      }
    )

    watch(
      () => props.options,
      (options) => {
        // Ensure the currency code is present
        const code = self.app.$store.state.i18n.locale;
        const locale = languages.find((lang)=> lang.code === code).numberFormat
        const newOptions = {
          ...options,
          locale : locale || 'en-US',
          hideCurrencySymbolOnFocus: true,
          hideGroupingSeparatorOnFocus: true,
          autoDecimalDigits: false,
          useGrouping: true,
          accountingSign: false,
          allowNegative: false,
          currency: "USD", // Default to USD if not provided
          currencyDisplay: "hidden",
          // hideGroupingSeparator: true,
          hideNegligibleDecimalDigitsOnFocus: true,
          // valueRange: { min: 0 },
}
        if (newOptions.currency) {
          setOptions(newOptions)
        } else {
          console.error("Currency code is required but missing.")
        }
      },
      { deep: true } // Ensure deep watch for nested object changes
    )
        

    return { inputRef }
  }
}
</script>