<template>
  <div class="edit-group">
    <div class="row mx-0">
      <div class="col-22 col-md-11 offset-1">
        <div class="form-group" v-if="clonedGroup">
          <label class="mb-1">{{ 'Group/Pen Name' | translate }}</label>
          <input
            type="text"
            class="form-control"
            name="group_name"
            data-vv-as="Group/Pen Name"
            v-validate="'required'"
            v-model="clonedGroup.name"
          />
          <span v-show="errors.has('group_name')" class="text-danger">
            <small>{{ 'The Group/Pen Name is required.' | translate }}</small>
          </span>
        </div>
      </div>
      <div class="col-22 col-md-6 offset-1 offset-md-0">
        <div class="form-group">
          <label class="mb-1">{{ 'Group/Pen Size' | translate }}</label>
          <currency-input
                key="group_size"
                  name="group_size"                  
                  v-model="clonedGroup.data.size"
                  data-vv-as="Group/Pen Size"
                  v-validate="'required'"
                  :options="{
                    currency: 'USD',
                    precision: 0,
                  }"
                />
          <span v-show="errors.has('group_size')" class="text-danger">
            <small>{{
              'The Group/Pen Size field is required.' | translate
            }}</small>
          </span>
        </div>
      </div>
      <div class="col-22 col-md-6 offset-1 offset-md-0">
        <div class="form-group">
          <label class="mb-1">{{ 'Type of Animal' | translate }}</label>
          <select
            name="group_type_of_animal"
            v-model="clonedGroup.data.type_of_animal"
            class="form-control"
          >
            <option :value="null" disabled>{{ 'Select' | translate }}</option>
            <option value="close-up">{{ 'Close-up' | translate }}</option>
            <option value="far-off">{{ 'Far-off' | translate }}</option>
            <option value="fresh">{{ 'Fresh' | translate }}</option>
            <option value="lactating">{{ 'Lactating' | translate }}</option>
            <option value="heifers-first-lactation">{{
              'Heifers (First Lactation)' | translate
            }}</option>
            <option value="heifers-growing">{{
              'Heifers (Growing)' | translate
            }}</option>
            <option value="mixed">{{ 'Mixed' | translate }}</option>
          </select>

          <span v-show="errors.has('group_type_of_animal')" class="text-danger">
            <small>{{
              'The Type of Animal field is required.' | translate
            }}</small>
          </span>
        </div>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col-22 col-md-5 offset-1">
        <div class="form-group">
          <label class="mb-1">
            {{
              $t('Average Cow Size') +
                ' (' +
                $t($getEquivalentUnit(evaluation.data.measurements, 'kg')) +
                ')'
            }}
          </label>
          <select
            name="cow_size"
            v-model="clonedGroup.data.cow_size"
            class="form-control"
          >
            <option :value="null" disabled>{{ 'Select' | translate }}</option>
            <option
              v-for="(cowSize, index) in cowSizes"
              :value="cowSize.value"
              :key="index"
            >
              {{ cowSize.label }}
            </option>
          </select>
          <span v-show="errors.has('cow_size')" class="text-danger">
            <small>{{
              'The Average Cow Size field is required.' | translate
            }}</small>
          </span>
        </div>
      </div>
      <div
        class="col-22 col-md-6 offset-1 offset-md-0"
        v-if="showMilkProductionField"
      >
        <div class="form-group">
          <label class="mb-1">
            {{
              $t("Milk Production") +
              " (" +
              $t(
                $getEquivalentUnit(
                  evaluation.data.measurements,
                  "kg"
                )
              ) +
              $t("/day)")
            }}
          </label>
          <currency-input
                key="group_milk_prod"
                  name="group_milk_prod"                  
                  v-model="computed_milk_production"
                  :data-vv-as="
                  $t('Milk Production') +
                    ' (' +
                    $t($getEquivalentUnit(evaluation.data.measurements, 'kg')) +
                    $t('/day)')
                  "
                  v-validate="'required'"
                  :options="{
                    currency: 'USD',
                    precision: {min: 0, max :2},
                  }"
                />
          <span v-show="errors.has('group_milk_prod')" class="text-danger">
            <small>{{
              'The Milk Production field is required.' | translate
            }}</small>
          </span>
        </div>
      </div>

      <div class="col-md-6">        
        <label for="mineral" class="mb-1">
                  {{'Zinpro Products Used?' | translate }}
                    <modal-info :value="'zinpro-products' + clonedGroup.id">
                       <template slot="modal-content">
                         <h2 class="h2 pt-3 pb-2 px-3 text-center">
                           {{
                             `Please indicate if any Zinpro products are fed within this pen.
                              If yes, select “Details” to select which products are used. 
                              You can select multiple products and indicate the inclusion level if known. 
                              Inclusion level is not required.`
                               | translate
                           }}
                         </h2>
                      <div class="row modal-bottom pb-3">
                        <a
                          href="#"
                          class="btn-cancel-modal d-flex justify-content-center"
                          data-dismiss="modal"
                          >{{ "Cancel" | translate }}</a
                        >
                      </div>
                       </template>
                   </modal-info>
                </label>
        <div class="zinpro-product-detail" style="display: flex; gap: 10px; align-items: flex-start;">
          <select
            name="mineral"
            v-model="clonedGroup.data.isUsedZinproProduct"
            class="form-control"
            data-vv-as="Avg. Cow Size"
          >
          <option :value="null" selected disabled>
                    {{ "Select" | translate }}
                  </option>
            <option :value="true">{{ "Yes" | translate }}</option>
            <option :value="false" :disabled="!!Object.keys(clonedGroup.data.selectedMinerals).length">{{ "No" | translate }}</option>
          </select>
          <button
            v-if="clonedGroup.data.isUsedZinproProduct" 
            type="button"
            class="btn btn-sm mr-4 btn-full--sm" style="padding: 8px 10px;" 
            @click="showMineralModal = true"
          >
            {{ "Details" | translate }}
          </button>
        </div>
      </div>

      
      <mineral-detail-modal
        :is-open="showMineralModal"
        @close="showMineralModal = false"
        @delete-mineral="
          (minerals) => (clonedGroup.data.selectedMinerals = minerals)
        "
        :selected-minerals="clonedGroup.data.selectedMinerals"
      >
      </mineral-detail-modal>

    </div>
    <div class="row mx-0">
      <div class="col-22 col-md-23 offset-1">
        <div class="form-group">
          <label class="mb-1">{{ 'Personal Notes' | translate }}</label>
          <modal-info :value="'personal_note' + clonedGroup.id">
            <template slot="modal-content">
              <h2 class="h2 pt-3 pb-2 px-3 text-center">
                {{
                  'Personal Notes: Not included when outputting reports.'
                    | translate
                }}
              </h2>
              <div class="row modal-bottom pb-3">
                <a
                  href="#"
                  class="btn-cancel-modal d-flex justify-content-center"
                  data-dismiss="modal"
                >
                  {{ 'Cancel' | translate }}
                </a>
              </div>
            </template>
          </modal-info>
          <textarea
            class="form-control"
            name="group_personal_notes"
            v-model="clonedGroup.personal_notes"
            data-vv-as="Personal Notes"
            rows="3"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col-22 col-md-23 offset-1">
        <div class="form-group">
          <label class="mr-3"
            >{{ 'Select Assessors' | translate }}
            <span class="required">*</span></label
          >

          <label class="label-checkbox label-checkbox--select-all">
            <input
              type="checkbox"
              name="checkAll"
              id="checkAll"
              disabled="user.role === 'intro'"
              :checked="check_all"
              value="1"
              @click="checkAll()"
            />
            <span class="checkbox"></span>
          </label>
          <label for="checkAll" class="label--font-thin"
          :style="{
                      opacity: (user.role === 'intro') ? 0.3 : 1,
                  }"
          >{{
            'Select/Deselect All' | translate
          }}</label>
        </div>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col-22 offset-1">
        <div
          class="modal fade show modal-overlay"
          tabindex="-1"
          id="info-transition-alert"
          role="dialog"
          style="display: block"
          v-if="showTransitionAlert"
        >
          <div class="modal-dialog modal-dialog--edit" role="document">
            <div class="modal-content edit-modal pt-0 px-0">
              <div class="modal-body my-0 py-0 px-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  style="margin: 0 5px"
                  @click="showTransitionAlert = false"
                >
                  &times;
                </button>
                <h2 class="h2 pb-2 pt-3 px-3 text-center">
                  {{
                    'If you want to export data for the Transition assessor you must first'
                      | translate
                  }}:
                </h2>
                <ul>
                  <li>
                    <p class="p--md px-3">
                      •
                      {{
                        'Add multiple groups for a single Evaluation to allow for comparison reporting.'
                          | translate
                      }}
                    </p>
                  </li>
                  <li>
                    <p class="p--md px-3">
                      •
                      {{
                        'It is advised to complete the Locomotion assessor before beginning the Transition assessor.'
                          | translate
                      }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row assessors-list">
          <div
            class="col-lg-12 col-xl-8"
            v-for="(assessor, index) in assessorTypes"
            :key="index"
          >
            <div class="form-group form-group--word-wrap">
              <label class="label-checkbox">
                <input
                  type="checkbox"
                  name="assessors"
                  :disabled="user.role === 'intro' && !['Freestall', 'Dirt Lot'].includes(assessor.name)"
                  :id="index + assessor.id"
                  v-model="clonedGroup.assessor_list"
                  @change="showTransitionPopup"
                  :value="assessor.id"
                />
                <span class="checkbox"></span>
              </label>
              <label
                :for="index + assessor.id"
                class="label--font-thin  d-inline"
                :style="{
                      opacity: (user.role === 'intro' && !['Freestall', 'Dirt Lot'].includes(assessor.name)) ? 0.3 : 1,
                  }"
                >{{ assessor.name | translate }}
              </label>
              <modal-info :value="assessor.id + clonedGroup.id">
                <template slot="modal-content">
                  <h2 class="h2 pb-2 pt-3 px-3 text-center">
                    {{ assessor.name | translate }}
                  </h2>
                  <p
                    class="p--md px-3"
                    v-for="(description, index) in assessor.description"
                    :key="index"
                  >
                    {{ description | translate }}
                  </p>
                  <div class="row modal-bottom pb-3 mt-3">
                    <a
                      href="#"
                      class="btn-cancel-modal d-flex justify-content-center"
                      data-dismiss="modal"
                      >{{ 'Cancel' | translate }}</a
                    >
                  </div>
                </template>
              </modal-info>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mx-0">
      <div class="col-22 offset-1">
        <span
          v-show="checkboxPermission === true"
          class="permission text-danger mb-3"
        >
          <small>{{
            'You need to click at least one of the checkboxes!' | translate
          }}</small>
        </span>
      </div>
    </div>

    <div class="row mx-0">
      <div class="col-22 offset-1">
        <button
          type="button"
          class="btn btn-sm mr-4 btn-full--sm"
          @click="saveGroupChanges()"
        >
          {{ 'Save' | translate }}
        </button>
        <button
          data-toggle="collapse"
          data-parent="#accordion"
          :href="['#groupsAccordion' + index]"
          aria-expanded="false"
          :aria-controls="['groupsAccordion' + index]"
          class="btn-cancel btn-link btn-full--sm d-none d-md-inline bg-transparent"
          @click="cancelGroupChanges()"
        >
          {{ 'Cancel' | translate }}
        </button>
        <button
          data-toggle="collapse"
          data-parent="#accordion"
          :href="['#groupsAccordion' + index]"
          aria-expanded="false"
          :aria-controls="['groupsAccordion' + index]"
          class="btn btn-link btn-secondary btn-full--sm d-md-none mt-2"
          @click="cancelGroupChanges()"
        >
          {{ 'Cancel' | translate }}
        </button>
      </div>
    </div>

    <div class="row mx-0">
      <div class="col-22 col-md-23 offset-1">
        <div v-if="errorMessage" class="alert alert-danger mt-3" role="alert">
          {{ 'Please fill out the required fields!' | translate }}
        </div>
        <div
          v-if="confirmMessage"
          class="alert alert-primary mt-3"
          role="alert"
        >
          {{ 'This group has been updated!' | translate }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import convert from 'convert-units';
import ModalInfo from '@/components/misc/modal-info.vue';

import Evaluation from '@/libs/Classes/Evaluation.js';
import GroupClass from '@/libs/Classes/Group.js';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  GET_CREATE_GROUP,
  GET_EVALUATION_BY_ID,
  GET_EVALUATION_BY_ID_GETTER,
} from '../../store/types';
import { initializeAssessorDataByType } from '../../helperFunctions';
import MineralDetailModal from "@/components/misc/mineral-detail-modal.vue";
import CurrencyInput from "@/components/common/CurrencyInput.vue";



export default {
  components: {
    ModalInfo,
    MineralDetailModal,
    CurrencyInput
  },

  props: ['groupId', 'evaluationId', 'unit', 'index'],

  computed: {
    ...mapGetters('evaluations', [GET_EVALUATION_BY_ID_GETTER]),
    ...mapState({
      user: (state) => state.users.user,
      assessorTypes: state => state.assessorTypes,
      // evaluation: state => state.evaluations.evaluations,
      // evaluation: state => state.evaluations.evaluation,
    }),
    cowSizes() {
      const cow_sizes_in_kg = [408.2, 498.9, 589.6, 680.3, 771, 861.8, 952.5];
      const converted_cow_sizes = [];

      for (let i = 0; i < cow_sizes_in_kg.length; i++) {
        if (i !== 0) {
          let low_converted_value = parseInt(
            convert(cow_sizes_in_kg[i - 1])
              .from('kg')
              .to(
                this.$getEquivalentUnit(this.evaluation.data.measurements, 'kg')
              )
              .toFixed(0)
          );

          let high_converted_value = parseInt(
            convert(cow_sizes_in_kg[i])
              .from('kg')
              .to(
                this.$getEquivalentUnit(this.evaluation.data.measurements, 'kg')
              )
              .toFixed(0)
          );

          converted_cow_sizes.push({
            value: cow_sizes_in_kg[i - 1] + '-' + cow_sizes_in_kg[i],
            label: low_converted_value + '-' + high_converted_value,
          });
        }
      }
      return converted_cow_sizes;
    },

    showMilkProductionField() {
      return [
        'fresh',
        'lactating',
        'heifers-first-lactation',
        'mixed',
      ].includes(this.clonedGroup.data.type_of_animal);
    },

    computed_milk_production: {
      get() {
        if (!this.groupClass?.group?.data) return null;

        if (this.evaluation.data.measurements === 'imperial') {
          return this.groupClass.group.data.imperialMilkProduction ?? this.groupClass.group.data.milkProduction;
        } else {
          return this.groupClass.group.data.metricMilkProduction ?? this.groupClass.group.data.milkProduction;
        }
      },
      set(value) {
        if (!value) return;
        
        const numValue = Number(value);
        if (this.evaluation.data.measurements === 'imperial') {
          this.groupClass.group.data.imperialMilkProduction = numValue;
          this.groupClass.group.data.metricMilkProduction = Math.round(numValue * 0.45359237);
        } else {
          this.groupClass.group.data.metricMilkProduction = numValue;
          this.groupClass.group.data.imperialMilkProduction = Math.round(numValue / 0.45359237);
        }
        
        this.groupClass.setMilkProduction(numValue);
        this.$forceUpdate(); 
      }
    },

    check_all() {
      return (
        this.clonedGroup.assessor_list.length ===
        this.$store.state.assessorTypes.length
      );
    },
  },

  data() {
    return {
      isCheckAll: false,
      clonedGroup: null,
      confirmMessage: false,
      checkboxPermission: false,
      errorMessage: false,
      groupClass: null,
      showTransitionAlert: false,
      showedTransitionAlert: false,
      group: null,
      evaluation: null,
      showMineralModal: false,
    };
  },
  
  async created() {
    // if (this.evaluation._id !== this.evaluationId)
    this.evaluation = this[GET_EVALUATION_BY_ID_GETTER](this.evaluationId);
    console.log(this.evaluation);
    this.group = _.cloneDeep(this.evaluation.groups[this.groupId]);
    this.clonedGroup = _.cloneDeep(this.group);
    this.clonedGroup = { ...this.group, data: { isUsedZinproProduct: null, ...this.group.data, selectedMinerals: { ...(this.group.data.selectedMinerals || {} )} } }
    const evaluationClass = new Evaluation(this, this.evaluation);
    this.groupClass = new GroupClass(this, evaluationClass, this.clonedGroup);
    
  },

  methods: {
    ...mapActions('evaluations', [GET_EVALUATION_BY_ID]),
    ...mapActions('groups', [GET_CREATE_GROUP]),
    checkAll() {
      this.clonedGroup.assessor_list = !this.check_all
        ? this.$store.state.assessorTypes.map(assessorType => assessorType.id)
        : [];

      this.showTransitionPopup();
    },

    async saveGroupChanges() {
      try {
        this.clonedGroup.assessor_list.length === 0
          ? (this.checkboxPermission = true)
          : (this.checkboxPermission = false);

        this.$validator.validateAll().then(result => {
          if (this.checkboxPermission === true) {
            result = false;
          }
          if (result === true) {
            this.errorMessage = false;

            this.checkboxPermission = false;
            this[GET_CREATE_GROUP]({
              group: this.clonedGroup,
              evaluationId: this.evaluation._id,
            }).then(() => {
              if (Array.isArray(this.evaluation.groups)) {
                let groupIndex = this.evaluation.groups.findIndex(
                  group => group.id === this.clonedGroup.id
                );
                this.evaluation.groups[groupIndex] = _.cloneDeep(
                  this.clonedGroup
                );
              } else {
                this.evaluation.groups[this.clonedGroup.id] = _.cloneDeep(
                  this.clonedGroup
                );
              }
              this.$forceUpdate();
            });

            console.log(this.clonedGroup.assessor_list);
            this.group.assessor_list =
              this.clonedGroup && this.clonedGroup.assessor_list;
            this.confirmMessage = true;

            const difference = _.differenceWith(
              this.group.assessor_list,
              this.evaluation.order,
              _.isEqual
            );

            console.log({ difference });

            if (
              !!this.evaluation.order &&
              difference.length !== 0 &&
              !this.evaluation.order.includes(difference)
            ) {
              // add add the newly add assessors in the group
              this.evaluation.order = this.evaluation.order.concat(difference);
              difference.forEach(assessorType => {
                const assessor = initializeAssessorDataByType(
                  this,
                  assessorType
                );
                console.log({ assessor, assessorType });
                this.clonedGroup.assessors[assessorType] = assessor;
              });
            }

            setTimeout(() => {
              this.confirmMessage = false;
            }, 5000);
          } else {
            this.errorMessage = true;

            setTimeout(() => {
              this.errorMessage = false;
            }, 5000);
          }
        });
      } catch (error) {
        setTimeout(() => {
          this.errorMessage = false;
        }, 5000);
      }
    },

    cancelGroupChanges() {
      this.clonedGroup = _.cloneDeep(this.group);
    },

    deleteGroup() {
      const group_data = {
        evaluationId: this.$route.params.id,
        groupId: this.clonedGroup.id,
      };

      // Delete Groups and Assessors
      if (!Vue.offlineMode()) {
        let deleteGroup = new Promise((resolve, reject) => {
          Vue.postRequest('deleteGroup', group_data, resolve, reject);
        }).then(response => {
          Vue.deleteGroup(this.evaluationId, this.clonedGroup.id);
        });
      } else {
        Vue.deleteGroup(this.evaluationId, this.clonedGroup.id);
      }
    },

    showTransitionPopup() {
      if (
        this.clonedGroup.assessor_list.includes('19') &&
        !this.clonedGroup.assessor_list.includes('3')
      ) {
        if (!this.showedTransitionAlert) {
          this.showTransitionAlert = true;
          this.showedTransitionAlert = true;
        }
        if (!this.clonedGroup.assessor_list.includes('3')) {
          this.clonedGroup.assessor_list.push('3');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.assessors-list {
  .ml-1,
  .mx-1 {
    margin-left: 0 !important;
  }

  label {
    font-size: 13px;
  }

  .col-md-8 {
    padding-left: 0;
    padding-right: 0;
  }

  .zinpro-product-detail {
    display: flex;
    gap: 10px;
    align-items: flex-start;
  }
}
</style>
