<template>
  <div class="container-dashboard container-dashboard-home">
    <div class="logo-overlay">
      <!-- <img src="@/assets/img/FirstStep-RGB-Line.png" alt="FirstStep" /> -->
    </div>
    <div class="container">
      <div class="row">
        <div class="col-24 mx-auto">
          <div class="card card--dashboard">
            <div class="card-body card-body--dashboard">
              <h1 class="text-center h1 h1--dashboard">
                {{ 'What Would You Like To Do Today?' | translate }}
              </h1>
              <router-link
                v-if="!user"
                to="/login/"
                class="btn btn-dashboard btn-block"
                active-class="active"
                exact
                >{{ 'Log In' | translate }}</router-link
              >
              <!--							<router-link v-if="!user" to="/login/" class="btn btn-dashboard btn-block" active-class="active" exact>{{"Economic/ROI Calculator" | translate}}</router-link>-->
              <router-link
                v-if="user"
                to="/quick-start/"
                class="btn btn-dashboard mt-2"
                :class="{ 'disabled-btn':  user.role === 'intro' && allEvaluations.length >= 3 }"
                active-class="active"
                exact
                @click.native.prevent=" user.role === 'intro' && allEvaluations.length >= 3 ? null : $router.push('/quick-start/')"
                >{{ 'Quick Start Evaluation' | translate }}</router-link
              >
              <router-link
                v-if="user"
                to="/evaluations/new"
                class="btn btn-dashboard mt-2"
                :class="{ 'disabled-btn': user.role === 'intro' && allEvaluations.length >= 3 }"
                active-class="active"
                exact
                @click.native.prevent="user.role === 'intro' && allEvaluations.length >= 3 ? null : $router.push('/evaluations/new')"
                >{{ 'Start New Evaluation' | translate }}</router-link
              >
              <router-link
                v-if="user"
                to="/evaluations"
                class="btn btn-dashboard mt-2"
                active-class="active"
                exact
                >{{ 'View Evaluations' | translate }}</router-link
              >
              <router-link
                v-if="user && user.role !== 'data_entry'"
                to="/reports"
                class="btn btn-dashboard mt-2"
                active-class="active"
                exact
                >{{ 'Create A Report' | translate }}</router-link
              >
              <router-link
                v-if="user && $store.state.adminRoles.includes(user.role)"
                to="/admin/invite-customer"
                :class="[
                  'btn',
                  'btn-dashboard',
                  'mt-2',
                  { disabled: !isOnline },
                ]"
                active-class="active"
                exact
                >{{ 'Invite A Customer' | translate }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader';
import AppFooter from '../components/AppFooter';
import { mapActions, mapGetters, mapState } from 'vuex';
import { GET_COMPANIES } from '../store/types';
export default {
  name: 'home',
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      //  user: null
    };
  },
  computed: {
    ...mapState({
      user: state => state.users.user,
      allEvaluations: state => state.evaluations.evaluations,
      // isOnline: state => state.isOnline,
    }),
    ...mapGetters(['isOnline']),
  },
  methods: {
    ...mapActions('company', [GET_COMPANIES]),
  },
  created() {
    this[GET_COMPANIES]();
    console.log({ isOnline: this.isOnline });
    // Temporary hide home page from guests
    // if (!this.user || !this.user._id) {
    // 	this.$router.push('/login')
    // }
    // this.user = this.$store.getters.getData('user');
  },
};
</script>

<style lang="scss" scoped>
.disabled-btn {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
  background-color: #ccc !important;
  border-color: #ccc !important;
  
  &:hover {
    background-color: #ccc !important;
    border-color: #ccc !important;
  }
}
</style>
